import React from 'react';
import { MobileMockup } from "components/previews";
import { MINUTES_IN_DAY } from 'logic/defaults';
import { OrderSchedulePreview } from 'components/previews/screens/OrderSchedulePreview';
import { NumberInput, SelectInput, TextInput } from 'common/Input';
import { Button } from 'common/Button';
import { useTranslation } from 'react-i18next';

const getBeautyNumber = (number) => {
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const ShiftSettings = ({
  shiftDraft,
  changeName,
  changeStartMinute,
  changeSlotDuration,
  changeSlotsQuantity,
  changeDishesInParallel,
  setStep,
}) => {
  const { t } = useTranslation();

  const START_MINUTE_STEP = 30;
  const minutesIter = MINUTES_IN_DAY / START_MINUTE_STEP - 1;
  const selectorOptions = [];

  for (let i= 0; i <= minutesIter; i++) {
    const optionMinute = START_MINUTE_STEP * i;
    const optionLabel = `${getBeautyNumber(Math.trunc(optionMinute / 60))}:${getBeautyNumber(optionMinute - (Math.trunc(optionMinute / 60) * 60))}`;
    selectorOptions.push({"label": optionLabel, "value": optionMinute});
  };

  const getEndTime = () => {
    const endMinute = shiftDraft.start_minute + (shiftDraft.slot_duration_minutes * shiftDraft.slots_quantity);
    return `${getBeautyNumber(Math.trunc(endMinute / 60))}:${getBeautyNumber(endMinute - (Math.trunc(endMinute / 60) * 60))}`
  };

  return (
    <div style={{display: "flex", flexDirection: "row", width: '100%', userSelect: 'none', padding: '24px 0'}}>
      <div style={{width: '30%', height: '100%', marginLeft: "5%"}}>
        <MobileMockup>
          <OrderSchedulePreview shift={shiftDraft} />
        </MobileMockup>
      </div>
      <div style={{width: '70%', height: '100%', paddingLeft: '5%', paddingRight: '5%'}}>
        <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <p style={{backgroundColor: "#FFF2D0", fontWeight: 600, borderRadius: "10px", padding: "5px 10px"}}>
            {t('changes_will_aply_next_day')}
          </p>
          <TextInput
            label='name_internal'
            placeholder='shift_name_placeholder'
            name='shift_name'
            value={shiftDraft.name}
            maxLength={50}
            onChange={(e) => changeName(e.target.value)}
            error={shiftDraft.name.length < 1 && 'shift_name_error'}
          />
          <div style={{display: 'grid', width: '100%', gridTemplateColumns: "1fr 1fr", gap: '16px'}}>
            <SelectInput
              label='shift_start_minute_label'
              isMulti={false}
              placeholder='shift_start_minute_placeholder'
              id='children'
              value={selectorOptions.find(op => op.value == shiftDraft.start_minute)}
              options={selectorOptions}
              hideSelectedOptions={false}
              closeMenuOnSelect={true}
              onChange={(e) => {
                changeStartMinute(e.value);
              }}
              isClearable={false}
            />
            <TextInput
              label='shift_end_minute_label'
              value={getEndTime()}
            />
          </div>
          <div style={{display: 'grid', width: '100%', gridTemplateColumns: "1fr 1fr", gap: '16px'}}>
            <NumberInput
              label='shift_slot_duration'
              value={shiftDraft.slot_duration_minutes}
              onChange={changeSlotDuration}
              step={5}
              min={10}
              max={(MINUTES_IN_DAY - shiftDraft.start_minute) / (shiftDraft.slots_quantity || 1)}
            />
            <NumberInput
              label='shift_slot_quantity'
              value={shiftDraft.slots_quantity}
              onChange={changeSlotsQuantity}
              step={1}
              min={1}
              max={(MINUTES_IN_DAY - shiftDraft.start_minute) / (shiftDraft.slot_duration_minutes || 10)}
            />
          </div>
          <div style={{display: 'grid', width: '100%', gridTemplateColumns: "1fr 1fr", alignItems: 'end', gap: '16px'}}>
            <NumberInput
              label='shift_dish_quantity'
              value={shiftDraft.dishes_in_parallel}
              onChange={changeDishesInParallel}
              step={1}
              min={1}
            />
            <div>
              <Button
                label='shift_dish_settings'
                style={{margin: '6px 0'}}
                onClick={() => setStep(2)}>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
