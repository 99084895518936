import React, { useState, useEffect, useContext } from "react";
import Spinner from "common/Spinner";
import ShiftModal from "./OrderAndPay/Modals/ShiftModal";
import ShiftsTable from "./Shifts/ShiftsTable";
import UnavailableFunctionality from "common/UnavailableFunctionality";
import { toast } from "react-toastify";
import { baseAuth } from "logic/api";
import { newShiftMock } from "./Shifts/newShiftMock";
import { useTranslation } from "react-i18next";
import { Context as AuthContext } from '../../../data/authContext';
import { Button } from "common/Button";
import { DeletePopup } from "./Modifiers/DeletePopup";

const LoadingOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-full w-full top-0 left-0 fixed z-9999 flex flex-col justify-center items-center">
        <Spinner>{t("Loading")}</Spinner>
      </div>
      <div className="bg-black opacity-20 top-0 left-0 h-full w-full fixed z-9999"/>
    </>
  );
};

const Shifts = () => {
  const { state: { selectedRestaurant } } = useContext(AuthContext);
  const { t } = useTranslation();

  const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'));
	const hasActiveOrderAndPayTab = restaurants.some(item => item.show_order_and_pay_tab_on_backoffice);

  const [shifts       , setShifts]        = useState([]);
  const [l3DishCount  , setL3DishCount]   = useState([]);
  const [shiftToRemove, setShiftToRemove] = useState(null);
  const [shiftToEdit  , setShiftToEdit]   = useState(null);
  const [shiftToCreate, setShiftToCreate] = useState(null);
  const [loading      , setLoading]       = useState(false);

  useEffect(() => {
		const isCorrectDeployVersion = JSON.parse(sessionStorage.getItem('correct_deploy_version'));

		if (isCorrectDeployVersion != null && !isCorrectDeployVersion) {
			toast.error(`${t('Error_deploy_version')}`);
			sessionStorage.setItem('correct_deploy_version', true);
		}

		setShifts([]);

		if (selectedRestaurant && selectedRestaurant.length > 0) {
			getData();
		}
	}, [selectedRestaurant]);

  const getData = async () => {
		setLoading(true);

		await baseAuth.get(`/restaurant/shifts/${selectedRestaurant[0].value}`)
			.then((response) => {
				const shifts = response.data.shifts.sort((a, b) =>
          (a.start_minute - b.start_minute) || (a.end_minute - b.end_minute)
        );

				setShifts(shifts);
        setL3DishCount(response.data.l3_dish_count);
			}).finally(() => {
				setLoading(false);
			});
	};

  const updateShift = async (shift, is_remove) => {
		setLoading(true);

		const newShift = JSON.parse(JSON.stringify(shift));
		newShift["type"] = is_remove ? "0" : "1";
		const body = {"shift": newShift};

		await baseAuth.post(`/restaurant/update_shift`, body)
      .then((response) => {
        const shifts = response.data.shifts.sort((a, b) =>
          (a.start_minute - b.start_minute) || (a.end_minute - b.end_minute)
        );

        setShifts(shifts);
        setShiftToEdit(null);
        setShiftToCreate(null);
        setShiftToRemove(null);
      }).catch((e) => {
        if (e.response.data.error == "E2") {
          toast.error(t('shift_overlap_error'));
        } else if (e.response.data.error == "E1") {
          toast.error(t('shift_sending_error'));
        }else {
          toast.error(t('shift_error'));
        }
      }).finally(() => {
        setLoading(false);
      });
    };

    const handleCreate = () => {
      newShiftMock["restaurant"] = selectedRestaurant[0].value;
      setShiftToCreate(newShiftMock);
    };

  if (!hasActiveOrderAndPayTab) return <UnavailableFunctionality title={'Orders'} />

  return (
    <>
      <Button
        label={'create_shift'}
        onClick={() => handleCreate()}
        className="mt-6"
      />
      <div className={`flex-auto flex flex-col items-center w-full bg-gray-100`}>
        {loading && <LoadingOverlay/>}
        <div className="flex-auto w-full">
          <div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "10px"}}>
            <ShiftsTable
              shifts={shifts}
              setShifts={setShifts}
              setLoading={setLoading}
              setShiftToRemove={setShiftToRemove}
              setShiftToEdit={setShiftToEdit}
            />
          </div>
        </div>
      </div>
      {(shiftToEdit || shiftToCreate) &&
				<ShiftModal
          shift={shiftToEdit || shiftToCreate}
          setShiftToEdit={shiftToEdit ? setShiftToEdit : setShiftToCreate}
          saveShift={updateShift}
          setShiftToRemove={setShiftToRemove}
          type={shiftToEdit ? 0 : 1}
          l3DishCount={l3DishCount}
          setL3DishCount={setL3DishCount}
        />
			}
      {shiftToRemove &&
        <DeletePopup
          title={'delete_shift_title'}
          message={'delete_shift_message'}
          onCancel={() => setShiftToRemove(null)}
          onConfirm={() => updateShift(shiftToRemove, true)}
          isOpen={shiftToRemove}
        />
			}
    </>
  );
};

export default Shifts;
