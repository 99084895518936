import React, { useRef, useState } from 'react';
import { ImageInputContainer } from './InputContainers';
import { Button, BUTTON_VARIANTS } from 'common/Button';
import { Icon, IconType } from 'common/Icon';
import { InfoTooltip } from 'common/InfoTooltip';
import { limitSizeUpload } from '../../logic/functions';
import { useTranslation } from 'react-i18next';
import { ErrorPopup } from 'common/ErrorPopup';

const DEFAULT_LABELS = ['add_image', 'change_image'];

export const ImageInput = ({
  image,
  info,
  labels = [],
  disabled,
  className,
  onUpload,
  onDelete,
}) => {
  const inputRef = useRef();
  const { t } = useTranslation();

  const [labelAdd, labelChange] = [...labels, ...DEFAULT_LABELS];
  const [popupOpened, setPopupOpened] = useState(false)

  const LIMIT = 2

  const selectImage = () => {
    if (disabled) return;
    inputRef.current.click();
  };

  const upload = event => {
    if (disabled) return;

    const limitReached = limitSizeUpload(event, LIMIT)

    if(limitReached){
      setPopupOpened(true)
      return;
    }

    const file = event.target.files[0];
    
    if (file) {
      const url = URL.createObjectURL(file);
      onUpload({ url, file });
    }
  };

  const download = () => {
    window.open(image, '_blank');
  };

  return (
    <>
    <ImageInputContainer className={className}>
      <Button
        leftIcon={image ? <Icon type={IconType.ADD}/> : undefined}
        label={image ? labelChange : labelAdd}
        variant={BUTTON_VARIANTS[image ? "SECONDARY" : "PRIMARY"]}
        onClick={selectImage}
        disabled={disabled}
      />
      {image &&
        <>
          <Icon type={IconType.DOWNLOAD} onClick={download} size={20}/>
          <Icon type={IconType.TRASH} onClick={onDelete} disabled={disabled} size={20}/>
        </>
      }
      {info && !image &&
        <InfoTooltip info={typeof info === 'string' ? info : undefined} size='18rem'>
          {typeof info !== 'string' &&
            <p>{info}</p>
          }
        </InfoTooltip>
      }
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={upload}
        disabled={disabled}
      />
    </ImageInputContainer>

    <ErrorPopup
        isOpen={popupOpened}
        title={t('size_not_supported')}
        close={() => setPopupOpened(false)}
    >
      <p style={{padding: "0.5rem 1rem"}}>
        {t('file_size_must_be_under')} {`${LIMIT}MB`}
      </p>
    </ErrorPopup>

    </>
  );
};
