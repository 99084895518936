import { Icon, IconType } from 'common/Icon';
import { ToggleSwitch } from 'common/ToggleSwitch';
import { baseAuth } from 'logic/api';
import React from 'react';

const ShiftRow = ({shift, setShifts, setLoading, setShiftToRemove, setShiftToEdit}) => {
  const getBeautyNumber = (number) => {
    return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
		});
  };

  const getHours = (shift) => {
		const start_minute = shift.start_minute;
		const start_hour = `${getBeautyNumber(Math.trunc(start_minute / 60))}:${getBeautyNumber(start_minute - (Math.trunc(start_minute / 60) * 60))}`;
		const end_minute = shift.start_minute + (shift.slots_quantity * shift.slot_duration_minutes);
		const end_hour = `${getBeautyNumber(Math.trunc(end_minute / 60))}:${getBeautyNumber(end_minute - (Math.trunc(end_minute / 60) * 60))}`;

		return `${start_hour}h - ${end_hour}h`;
	};

	const changeActiveState = async (new_state) => {
		setLoading(true);
		const newShift = JSON.parse(JSON.stringify(shift));
		newShift["active"] = new_state;
		newShift["type"] = "1";
		const body = {"shift": newShift};

		await baseAuth.post(`/restaurant/update_shift`, body)
			.then((response) => {
				const shifts = response.data.shifts.sort((a, b) =>
          (a.start_minute - b.start_minute) || (a.end_minute - b.end_minute)
        );
				console.log(shifts);
				setShifts(shifts);
			}).finally(() => {
				setLoading(false);
			});
		}

  return (
    <div style={{border: "2px solid #FCFCFC", display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr .7fr", gap: '16px', textAlign: 'center', width: "100%", backgroundColor: "#FCFCFC", justifyContent: 'space-around', fontSize: "15px", fontWeight: 500, padding: "10px", marginTop: "10px", alignItems: 'center', borderRadius: '15px'}}>
			<div style={{backgroundColor: "#EEF9F5", color: "#404040", border: "1px solid #AFDCCA", padding: '3px 5px', borderRadius: "99999px"}}>
				{shift.name}
			</div>
			<div>{getHours(shift)}</div>
			<div>{shift.slot_duration_minutes} min</div>
			<span style={{backgroundColor: "#EDE0F5", padding: "2px 15px", borderRadius: "5px", maxWidth: 'fit-content', justifySelf: 'center'}}>
				{shift.slots_quantity}
			</span>
			<span style={{backgroundColor: "#E2FEF1", padding: "2px 15px", borderRadius: "5px", maxWidth: 'fit-content', justifySelf: 'center'}}>
				{shift.dishes_in_parallel} {shift.dishes_in_parallel == 1  ? "plato" : "platos"}
			</span>
			<div>
				<ToggleSwitch
					onChange={() => changeActiveState(!shift.active)}
					checked={shift.active}
				/>
			</div>
			<div className={`h-full text-start text-xs font-normal py-2 items-center justify-center flex`}>
				<div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
					<Icon type={IconType.TRASH} onClick={() => setShiftToRemove(shift)}/>
					<Icon type={IconType.PENCIL} onClick={() => setShiftToEdit(shift)}/>
				</div>
			</div>
		</div>
  );
};
export default ShiftRow;
