const info = {
  "info_new": "Usuarios registrados que han pagado por primera vez",
  "info_recurrent": "Usuarios habituales, con más de un pago con Yumminn",
  "info_users_who_paid": "Usuarios registrados que han hehcho uno o más pagos",
  "info_payment_rates_per_user": "Porcentajes pagos por usuario",
  "paid_with": "Pagado con",
  "info_cashback_granted": "Total de cashback generado en el periodo, redimido o no",
  "info_cashback_redeemed": "Cashback utilizado en pagos",
  "info_points_granted": "Total de puntos generado",
  "info_total_reviews": "Según el período seleccionado",
  "info_average_stars": "Valoración promedio de todos los restaurantes",
  "info_today": "Total de pagos de hoy",
  "info_last_week": "Total de pagos de la semana en curso",
  "info_last_month": "Total de pagos del mes en curso",
  "info_accumulated": "Total de pagos con Yumminn",
  "info_period_payments": "Total de pagos desde {{from}} hasta {{to}}. Incluye propinas.",
  "info_period_tips": "Total de propinas desde {{from}} hasta {{to}}",
  "info_period_ratings": "Valoraciones desde {{from}} hasta {{to}}",
  "info_period_restaurants": "Restaurantes desde {{from}} hasta {{to}}",
  "info_period_loyalty": "Fidelización desde {{from}} hasta {{to}}",
  "info_active_users": "Registros de usuarios registrados que han pagado",
  "info_restaurants_with_reviews": "Ver detalle en listado de restaurantes o en sección de reseñas",
  "info_restaurants_with_cashback": "Restaurantes con loyalty activo",
  "info_avg_cashback_per_user": "Media de cashback vigente por usuario",
  "info_avg_points_per_user": "Media de puntos vigentes por usuario",
  "info_with_payments": "Los restaurantes con pagos respecto al total de la base de datos",
  "info_payments_per_restaurant": "Media de pagos por restaurante",
  "info_avg_payments_with_cashback": "Media de pagos usando cashback",
  "info_new_users": "Nuevos usuarios en el período seleccionado",
  "info_recurrent_users": "Usuarios recurrentes en el período seleccionado",
  "info_payments_with_review": "Numero de reseñas después del pago",
  "info_registered_users": "Nuevos usuarios registrados y recurrentes",
  "info_restaurant_group_visibility": "Visibilidad del restaurante en la pantalla inicial del grupo",
  "info_locker_counter": "Utiliza el mostrador como alternativa a los lockers en caso de ser necesario (si no tienes lockers disponibles o si una comanda no cabe en el locker). El mostrador puede contener tantos pedidos como necesites.",
  "info_locker_active_disabled": "No se puede desactivar un locker con pedidos",
  "info_cross_sell_products": "Deben ser la mayor cantidad de productos posibles, con un mínimo de 4 productos. Se visualizarán los primeros 4, excluyendo la selección del comensal.",
  "info_default_language": "Utilizaremos este idioma para mostrar la carta cuando no existan traducciones disponibles en otros idiomas.",
  "info_available_languages": "Son los idiomas que los usuarios tendrán disponibles en la webapp.",
  info_kitchen_saturated: "Informa a tus comensales el tiempo de espera o desactiva los pedidos temporalmente desde aquí. <1>Mas info en este tutorial.</1>",
  info_kitchen_saturated_delay: "Tiempo de espera configurado: {{minutes}} minutos.",
  info_kitchen_saturated_pause: "El comensal podrá ver la carta, pero no podrá realizar el pedido durante este tiempo.",
  info_pause_undefined: "Los pedidos estarán pausados indefinidamente. Deberás activarlos manualmente.",
  languages: "Idiomas",
  info_modifier_group: "<0>Crea grupos</0> de modificadores para <2>organizar las opciones de tus productos</2>. Por ejemplo: <4>'Punto de la carne'</4> o <6>'Extras'</6>.",
  info_modifier_list: "Crea modificadores para <1>personalizar los productos</1> y <3>promover ventas adicionales</3>.",
  "available_languages": "Idiomas disponibles",
  "available_languages_saved": "Idiomas disponibles actualizados",
  "available_languages_error": "Error al actualizar los idiomas disponibles",
  "not_remove_default_language": "No se puede eliminar el idioma por defecto",
  "ai_translation_error": "Algo ha ido mal mientras se realizaban las traducciones",
  "default_language_saved": "Idioma por defecto guardado",
  info_preselected_coupon: 'Si <b>no está seleccionada</b>, el usuario deberá ingresar manualmente el código del cupón',
};

export const spanishTranslatorObject = {
  ...info,
  id: "ID",
  save: "Guardar",
  Home: "Inicio",
  Tables: "Mesas",
  Table: "Mesa",
  Settings: "Ajustes",
  Menu: "Menú",
  Ratings_and_reviews: "Valoraciones",
  QR_Codes: "Códigos QR",
  Restaurants: "Restaurantes",
  Value: "Volumen de pago",
  Orders: "Pedidos",
  "Orders validation": "Validación de pedidos",
  Avg_Ticket: "Ticket medio",
  Users: "Usuarios",
  Avg_Rating: "Puntuación media",
  Avg_Rating_Mobile: "Avg rating",
  Avg_Tip: "Propina promedio",
  Logout: "Salir",
  Table_statuses: "Estados de la mesa",
  Shifts: "Turnos",
  EditTablesSectors: "Editar mesas y sectores",
  Number: "Número",
  Addnewtable: "  Añadir nueva mesa",
  Addnewsector: "Agregar nuevo sector",
  Deletesector: "Eliminar sector",
  "Delete opening hour": "Eliminar horario de apertura",
  Deletetable: "Eliminar mesa",
  update_ok: "Actualizar ok",
  delete_ok: "Eliminado correctamente",
  forgot_password: "¿Has olvidado tu contraseña?",
  Company_Name: "Nombre de empresa",
  Company_CIF: "Empresa CIF",
  Company_Bank: "Banco de la empresa",
  Company_IBAN: "Empresa IBAN",
  Owner_Name: "Nombre del dueño",
  Owner_First_Name: "Nombre del propietario",
  Owner_Last_Name: "Apellido del propietario",
  Owner_CIF: "Propietario CIF",
  Owner_Phone: "Teléfono propietario",
  Owner_email: "Correo electrónico propietario",
  Account_holder: "Titular de la cuenta",
  Adyen_account_holder_code: "Código del titular de la cuenta Adyen",
  Owner: "Propietario",
  Company: "Empresa",
  Account: "Cuenta",
  Tips_account: "Cuenta de propinas",
  TPV_Key: "Clave TPV",
  TPV_Secret: "Clave secreta de TPV",
  TPV_Wildcard_Product_Name: "Producto comodín TPV",
  TPV_Wildcard_Product_Code: "Código producto comodín TPV",
  Description: "Descripción",
  Our_story: "Nuestra historia",
  Chef_name: "Nombre del chef",
  Chef_instagram: "Chef Instagram",
  Chef_story: "Historia del chef",
  Restaurant_Name: "Nombre restaurante",
  TAG_type_of_food: "TAG (tipo de comida)",
  Restaurant_Type: "Tipo de restaurante",
  Email: "Correo electrónico",
  Email_short: "Correo",
  Phone: "Teléfono",
  Address: "Dirección",
  Zip_code: "Código postal",
  City: "Ciudad",
  State: "Estado",
  Country: "País",
  Country_code: "Código de país",
  Currency_code: "Código de divisa",
  Website_link: "Enlace sitio web",
  Instagram_link: "Enlace Instagram",
  Facebook_link: "Enlace Facebook",
  Opening_hours: "Horario de apertura",
  Business_logo: "Logotipo de empresa",
  Drag_to_reorder: "Arrastre para reordenar",
  Chef_photo: "Chef de fotografía",
  Delete_profile_photo: "Eliminar foto de perfil",
  Delete_chef_photo: "Eliminar foto del chef",
  Delete_logo: "Eliminar logo",
  General: "General",
  Photos: "Fotos",
  Photo: "Foto",
  Booking: "Reserva",
  Bookings: "Reservas",
  BookingShifts: "Turnos de reserva",
  Configuration: "Configuración",
  "Stock control": "Control de stock",
  "Current state": "Estado actual",
  shift: "Turno",
  slot_duration: "Duración del slot",
  slot_qty: "Cantidad de slots",
  slot_diners: "Comesales por slot",
  booking: "reserva",
  bookings: "reservas",
  diners: "comensales",
  diner: "comensal",
  booking_period: "Periodo de reserva",
  creation_period: "Periodo de creación",
  creation_datetime: "Fecha y hora de creación",
  booking_datetime: "Fecha y hora de reserva",
  Sectors_and_location: "Sectores y ubicación",
  error_pos: "Error de TPV",
  Bank_and_owner_info: "Información del banco y del propietario",
  Monday: "Lunes",
  Tuesday: "Martes",
  Wednesday: "Miércoles",
  Thursday: "Jueves",
  Friday: "Viernes",
  Saturday: "Sábado",
  Sunday: "Domingo",
  Menu_comment: "Menú de comentarios",
  comments: "Comentarios del pedido",
  menu_dessert: "Menu de postres",
  Menu_link: "Menú de enlace",
  Menu_PDF: "Menús PDF",
  Delete_menu: "Eliminar menú",
  menu_drinks: "Bebidas de menú",
  Group_name: "Nombre del grupo",
  Item: "Artículo",
  Allergens: "Alérgenos",
  Price: "Precio",
  Add_new_item: "Agregar ítem nuevo",
  Add_new_group: "Agregar nuevo grupo",
  Add_new_menu: "Agregar nuevo menu",
  Delete_group: "Eliminar grupo",
  Delete_item: "Eliminar elemento",
  Food: "Comida",
  Drinks: "Bebidas",
  Dessert: "Postre",
  Comment: "Comentario",
  Documents: "Documentos",
  Upload_Menu: "Menú de carga",
  ratings_general: "Calificaciones generales",
  Service: "Servicio",
  Atmosphere: "Atmósfera",
  Ordered: "Orden",
  Last_7_days: "Últimos 7 días",
  Last_7_days_mobile: "Últ. 7 días",
  Month: "Mes",
  Overview: "Visión general",
  Reviews: "Reseñas",
  email: 'correo',
  email_plural: 'correos',
  password: "contraseña",
  login: "Acceder",
  Profile_photos: "Fotos de perfil",
  From: "De",
  To: "Hasta",
  "Candlestick interval": "Intérvalo vela",
  maximum: "máximo",
  "No agents to show": "Ningún agente a mostrar",
  Add_new_opening_hour: "Agregar nueva hora de apertura",
  cancel: "cancelar",
  logout: "salir",
  Admin: "Administrador",
  Onboarding_Form: "Formulario de incorporación",
  Business_Type: "Tipo de negocio",
  Street_number: "Número de calle",
  Company_legal_name: "Razón social de la empresa",
  Company_NIF: "NIF de la empresa",
  Owner_birthdate: "Fecha de nacimiento del propietario",
  Bank_account_IBAN: "IBAN de la cuenta bancaria",
  Google_merchant_id: "Google merchant Id",
  Bank_account_owner_name: "Nombre del propietario de la cuenta bancaria",
  Create_accounts: "Crear cuentas",
  TPV_Platform: "Plataforma TPV",
  Send: "Enviar",
  New_restaurant: "Nuevo restaurante",
  New_company: "Nueva empresa",
  Name: "Nombre",
  acceso_a_cuestionario: "Acceso a cuestionario",
  acceso_a_cuestionario_explanation: "Acceso a cuestionario explanation",
  return_home: "Volver a la página de inicio",
  no_password: "Sin contraseña",
  update_password_ok: "Actualizar contraseña ok",
  change_password_text: "Elige tu nueva contraseña",
  new_password: "Nueva contraseña",
  reset_password: "Restablecer la contraseña",
  password_conditions: "Condiciones de contraseña",
  registrarse_para_mas_info: "Registrarse para mas info",
  loading: "cargando",
  per_page: "por página",
  quien_donde_buscas: "quien donde buscas",
  abre_modal: "Abre modal",
  pagina_anterior: "pagina anterior",
  pagina_posterior: "pagina posterior",
  search: "buscar",
  reset: "Reiniciar",
  more: "más",
  Day: "Día",
  registrate: "Registrate",
  dashboard: "tablero",
  employees: "empleados",
  alerts: "alertas",
  close_session: "Cerrar la sesión",
  Upload_Profile_photos: "Subir fotos de perfil",
  Is_favourite: "Es favorito",
  revisiones_totales: "reseñas totales",
  reviews: "reseñas",
  Calificación_total: "Calificación total",
  stars: "stars",
  Comida: "Comida",
  Servicio: "Servicio",
  Main_Image: "Imagen de portada",
  Upload_Main_Image: "Subir la imagen de portada",
  Add_New_Menu: "Añadir Menú",
  is_menu_active: "El menú está activo",
  Yumminn_Payments: "Resumen Pagos",
  Payments: "Pagos",
  "7days": "Últimos 7 días",
  "month": "Mes",
  "ytd": "Año hasta la fecha",
  "Do_Refund": "Escribe el importe a reembolsar",
  "Proceed": "Proceder",
  tpv_wildcard_product_code: "Código del producto comodín",
  tpv_wildcard_product_name: "Nombre del producto comodín",
  local_id: "Id del local",
  tpv_username: "Usuario del TPV",
  tpv_password: "Contraseña del TPV",
  Tip: "Propina",
  Tips: "Propinas",
  Options: "Opciones",
  edit: "Editar",
  delete: "Eliminar",
  Merchant_Account: "Cuenta merchant",
  Merchant_category_code: "Código de categoría merchant",
  restaurant_deleted: "Restaurante eliminado",
  company_deleted: "Empresa eliminada",
  Adyen_config: "Configuración de Adyen",
  tpv_info: "tpv info",
  "Pass KYC": "Pasar KYC",
  "KYC passed": "KYC pasado",
  tpv: "Tpv",
  save_group_first: "¡Guarda el grupo primero!",
  Dashboard: "Métricas",
  "Avg": "Media",
  "Previous avg": "Media período anterior",
  "Previous period": "Período anterior",
  "Current period": "Período actual",
  paid: "Pagado",
  "payment experience": "Experiencia de pago",
  'days of the month': "Días del mes en curso",
  "previous day": "Día anterior",
  "day": "día",
  "previous day average": "Media día anterior",
  "Months": "Meses",
  "January": "Enero",
  "February": "Febrero",
  "March": "Marzo",
  "April": "Abril",
  "May": "Mayo",
  "June": "Junio",
  "July": "Julio",
  "August": "Agosto",
  "September": "Septiembre",
  "October": "Octubre",
  "November": "Noviembre",
  "December": "Diciembre",
  "Date": "Fecha",
  "Todos": "Todos",
  "Payments with reviews": "pagos con reseña",
  "Payments with tips": "pagos con propina",
  "Is not admin or investor": "El usuario no es administrador ni inversor",
  "Invalid sent data": "Usuario o contraseña incorrectos",
  "Start_Date": "Fecha de inicio",
  "End_Date": "Fecha de fin",
  "Error_POS_explanation": "El pago se ha realizado correctamente <1>pero no se ha registrado en el TPV debido a un error</1>. Por favor, introduzca el pago de forma manual en el TPV.",
  "Variation": "Variación",
  "Create new company": "Crear nueva empresa",
  "Create new restaurant": "Crear nuevo restaurante",
  "Download logos": "Descargar logos",
  "Download logo": "Descargar logo",
  Last_30_days: "Últimos 30 días",
  Last_30_days_mobile: "Últ. 30 días",
  "tips vs payment": "propina sobre pagos",
  Today: "Hoy",
  Between: "De",
  and: "a",
  "Data today": "Diario",
  "Loyalty total granted": "Créditos de fidelización otorgados",
  "Loyalty used": "Créditos de fidelización utilizados",
  "Loyalty unused": "Créditos de fidelización no utilizados",
  "loyalty program information": "Información del programa de fidelización",
  "loyalty program status": "Estado del programa de fidelización",
  "Minimum to grant": "Mínimo para generar créditos",
  "Minimum to redeem": "Mínimo para consumir créditos",
  "Earning percentage": "Porcentaje para generar créditos",
  "Loyalty program enabled": "Programa de fidelización activado",
  "Loyalty program disabled": "Programa de fidelización desactivado",
  "Reports email": "Email reportes",
  Transactions: "Transacciones",
  "Search table": "Buscar mesa",
  "Search sector": "Buscar sector",
  Search: "Buscar",
  "No results match your search criteria": "Sin resultados para la búsqueda realizada",
  "No payments": "No se encuentran transacciones para esta búsqueda",
  Status: "Estado",
  status: "estado",
  "Date and hour": "Fecha y hora",
  "Visibility schedule": "Horario de visibilidad",
  "Always visible": "Siempre visible",
  "Modified": "Última modificación",
  Modifiers: "Modificadores",
  "Modifier": "Modificador",
  Items: "Ítems",
  Stock: "Stock",
  "Ordered units": "Unidades pedidas",
  "Available stock": "Stock disponible",
  "Error updating stock": "Error actualizando stock",
  "Active": "Activo",
  "Back": "Atrás",
  "No menus added yet": "No se han añadido menús todavía",
  "Internal_menus": "Menús internos",
  "External_menu": "Menú externo",
  "New_menu": "Nuevo menú",
  Refund: "Reembolso",
  Revenue: "Ingresos",
  general_discount: "descuento general",
  code_coupon: "código de descuento",
  "excluding tips": "excluyendo propinas",
  "All day": "Todo el día",
  to: "a",
  Apply: "Aplicar",
  "Everyday": "Todos los días",
  Delete_schedule: "Borrar horario",
  Delete_time_range: "Borrar intervalo de tiempo",
  "Select days": "Selecciona los días",
  "Select time range": "Selecciona las horas",
  "Type": "Tipo",
  "Add time range": "Añade intervalo de tiempo",
  "Add new schedule": "Añade nuevo horario",
  "Upload PDF file with menu": "Sube un PDF con tu menú",
  "Add a menu name": "Añadir nombre del menú",
  Daily: "24 horas",
  Commission: "Comisión",
  "About enable or disable": "Habilitar o deshabilitar 'acerca de'",
  "You cannot add a new menu schedule": "¡No puedes añadir un nuevo horario al menú sin antes añadir algún día al menos al horario que nuevo que ya estás editando!",
  Loading: "Cargando...",
  About: "Acerca de",
  Business_Name: "Razón social",
  "Loyalty": "Programa de fidelización",
  Export_Csv: "Exportar CSV",
  next: "Siguiente",
  Next: "Siguiente",
  export_svg_qr_codes: "Exportar QR en .svg",
  export_pdf_qr_codes: "Exportar QR en .pdf",
  Previous: "Anterior",
  "Top_restaurants": "Top restaurantes",
  "Days": "Días",
  "days": "días",
  "Hours": "Horas",
  "hours": "horas",
  "Minutes": "Minutos",
  "weeks": "semanas",
  "months": "meses",
  "Years": "Años",
  "avg_tips_explanation": "Es el importe total de las propinas dividido entre el número total de propinas del período escogido.",
  "payments_explanation": "Es la suma del importe de los pagos incluyendo propinas del período escogido.",
  "avg_payments_explanation": "Es la división de la suma total de todos los pagos del período escogido entre el total de días de dicho período.",
  'avg_ticket_explanation': "Es la división de la suma total de todos los pagos del período escogido entre la suma de transacciones de dicho período.",
  'tips_explanation': "Es la suma del importe total de propinas del período escogido.",
  'transactions_explanation': "Es la suma del número de transacciones realizadas durante el período escogido.",
  'avg_transactions_explanation': "Es la suma del número de transacciones realizadas durante el período escogido entre los días de dicho período si el período es 'Días'. Si el período es 'Meses', se divide entre meses; si el período es 'Años', se divide entre los años, y si es el período de 24 horas, se muestra la media de transacciones por hora.",
  'reviews_explanation': "Es la suma del número de reseñas recibidas durante el período escogido.",
  'avg_reviews_explanation': "Es la suma del número de reseñas recibidas durante el período escogido divida entre los días de dicho período.",
  'avg_rating_reviews_explanation': "Es la suma de las puntuaciones recibidas durante el período escogido dividida entre el número total de puntuaciones recibidas durante dicho período.",
  'payments_with_reviews_explanation': "Es el porcentaje de pagos sobre el total del período escogido con el que han dejado una reseña.",
  'payments_with_tips_explanation': "Es el porcentaje de pagos sobre el total de pagos del período escogido que incluyen propinas.",
  'tips_vs_payments_explanation': "Es el porcentaje sobre un pago que corresponde a la propina.",
  'users_explanation': "Es el número total de usuarios registrados durante el período escogido.",
  'email_users_explanation': "Es el número total de usuarios registrados vía email durante el período escogido.",
  'google_users_explanation': "Es el número total de usuarios registrados vía google durante el período escogido.",
  'facebook_users_explanation': "Es el número total de usuarios registrados vía facebook durante el período escogido.",
  "Facebook users": "Usuarios Facebook",
  "Email Users": "Usuarios email",
  "Google users": "Usuarios Google",
  'year': "Año",
  Total: "Total",
  "Table status": "Estado de mesas",
  Product: "Producto",
  Products: "Productos",
  "Total bill": "Total recibo",
  "Remaining": "Restante",
  "Scan qr": "Escanea el código QR para acceder al feed de mesas desde tu móvil.",
  "pos error": "Sin registrar en TPV",
  "has paid": "pagó",
  "no tables": "No hay mesas con este estado",
  "Reports": "Reportes",
  Crm: "CRM",
  Invoices: "Facturas",
  "name": "Nombre",
  "surname": "Apellido",
  "phone": "Telefono",
  "date register": "Fecha registrado",
  "last visited": "Fecha visitado",
  "accumulated tips": "Propinas Acumulados",
  "generated loyalty": "Descuento generado",
  "used loyalty": "Descuento usado",
  "loyalty left": "Descuento restante",
  "total": "Total",
  Companies: "Empresas",
  "Payments with client": "pagos con usuario",
  "Payments with review": "pago con reseñas",
  "payments_with_clients_explanation": "Es el número total de pagos realizados con un usuario registrado y logueado",
  "clients_registered_after_payment": "usuarios registrados tras el pago",
  "clients_registered_after_payment_explanation": "Es el porcentaje de usuarios del total de usuarios registrados que se ha registrado justo después de realizar un pago",
  "users_registered_by_restaurant": "usuarios registrados por restaurante",
  "users_registered_by_restaurant_explanation": "Es el porcentaje de usuarios registrados por restaurante con respecto al total de usuarios de Yumminn",
  "Registered users": "Usuarios registrados",
  "users_with_loyalty": 'Usuarios con loyalty',
  "loyalty_granted": 'Loyalty generado',
  "loyalty_used": 'Loyalty usado',
  "loyalty_used_explanation": "Es el total de euros de fidelidad usados.",
  "loyalty_granted_explanation": "Es el total de euros de fidelidad generados para los usuarios.",
  "users_with_loyalty_explanation": "Es el total de usuarios con fidelidad accumulado.",
  "prev_used": "Consumido Período anterior",
  "actual_used": "Consumido Actual",
  "prev_granted": "Generado Período anterior",
  "actual_granted": "Generado Actual",
  Order_and_pay: "Pedir y pagar",
  Synchronize: "Sincronizar",
  "Yes, synchronize": "Sí, sincronizar",
  "No, cancel": "No, cancelar",
  "confirm_synchronize_text_1": "Si has realizado cambios de productos y/o categorías en tu TPV desde la última sincronización,",
  "confirm_synchronize_text_2": "se verán reflejados en tu carta Yumminn.",
  "confirm_synchronize_text_3": "Nos quedamos siempre con los últimos cambios, sean del TPV o de Yumminn.",
  "Do you wish to continue?": "¿Deseas continuar?",
  Create_category: "Crear Categoría",
  Edit_category: "Editar Categoría",
  "TPV name": "Nombre en TPV",
  Saved_category: "Categoría se ha guardado correctamente",
  Error_saving_category: "Hubo un problema creando tu categoría!",
  Error_synchronize: "Hubo un problema con la sincronización!",
  "default": "por defecto",
  "tpv_category": "Categoría Tpv",
  "visibility_schedule": "Horario Visibilidad",
  "visible": "Visible",
  "not_visible": "no visible",
  Visibility: 'Visibilidad',
  Modifier: "Modifier",
  "Modifier Category": "Categoría de Modificador",
  Upload_Product_photos: "Subir foto del producto",
  "Suelta tu imagen aquí o haz clic para": "Suelta tu foto aquí o haz clic para",
  "This restaurant has no data to collect yet": "Este restaurante no tiene información para mostrar todavía",
  'payments with reviews': "Pagos con reseña",
  'payments with user': "Pagos con usuario",
  'Show all restaurants': 'Mostrar restaurantes sin pagos en este periodo',
  'assign': 'Asignar',
  'assigned': 'Asignado',
  'confirm manager': 'Estas seguro de ponerte como "account manager" de este restaurante?',
  'confirm change manager': 'Te quieres poner como "account manager"?',
  'loyalty': 'Fidelización',
  'no restaurants with payments': 'No hay restaurantes con pagos en este periodo',
  "events": "Eventos",
  "Create_event": "Crear nuevo evento",
  "amount": "Importe",
  "event_date": "Fecha del evento",
  "No_events": "No hay eventos creados",
  "detail": "Detalle",
  "created_event_date": "Fecha creación",
  "Status_payment": "Estado de pago",
  "Hour": "Hora",
  "Concept": "Concepto",
  "Occasion": "Asunto",
  "Pending": "Pendiente",
  "Confirm delete": "Confirma el borrado de este elemento?",
  "Suelta tu imagen aquí o haz clic para ": "Suelta tu foto aquí o haz clic para",
  "Prod_max": "Productos máximo",
  "Prod_min": "Productos mínimo",
  "Optional": "Opcional",
  "No Limit": "Sin límite",
  "ultima sincronizacion": "Última sincronización:",
  "Error_saving_product": "Hubo un error al guardar el producto",
  "Saved_product": "Producto guardado",
  "empty_field": "No se han rellenado todos los campos obligatorios",
  "Error_deleting_time_range": "Hubo un problema al eliminar el Rango de tiempo",
  "Deleted_time_range": "Rango de tiempo eliminado",
  "Updated_time_range": "Rango de tiempo actualizado",
  "Error_updating_time_range": "Hubo un problema al actualizar el rango de tiempo",
  "Deleted_schedule": "Horario eliminado",
  "Error_deleting_schedule": "Hubo un problema al eliminar el horario",
  "saving": "Guardando",
  "Updated_modifier": "Modificador actualizado",
  "Edit_event": "Editar evento",
  "check_link": "Ver enlace",
  "send_order_now": "Enviar la orden al TPV ahora mismo",
  "send_order_again": "Volver a enviar la orden al TPV",
  "reprint_yumminn": "Imprimir por Yumminn Print",
  "Print": "Imprimir",
  confirm: "Confirmar",
  Delete_main_image: "Borrar foto de portada",
  "Upload Business logo": "Subir logo",
  Order: "Pedido",
  filtering_by: 'Filtrando por',
  delivery_address: "Dirección de entrega",
  created: "Creado",
  received: "Recibido",
  "ready_to_pick_up": "Listo para recoger",
  "pending_cash": "Efectivo pendiente",
  "deliver_order": "Entregar pedido",
  "delivered": "Entregado",
  "cancel_delivery": "Cancelar entrega",
  search_by: "Buscar por",
  stock_item_search_input_placeholder: "#Nombre",
  order_search_input_placeholder: "#Pedido, Teléfono, Dirección, Correo o Nombre",
  error_performing_search: "Error realizando búsqueda",
  no_matches_found: "Ninguna coincidencia encontrada",
  "promised_delivery": "Entrega prometida",
  finalized: "Finalizado",
  Finalized: "Finalizado",
  "POS error": "Error de TPV",
  "error_sending_info_to_pos": "Error al enviar datos al TPV",
  "waiting_send_info_to_pos": "Pendiente de envio al TPV",
  closed: "Cerrado",
  Client_name: "Nombre cliente",
  "refund_successful": "Pago reembolsado con éxito",
  "adyen_refund_payment except: Cannot refund payments after 1 hour of being done.": "No se pueden devolver los pagos después de 1 hora de haber sido realizados",
  "Performance": "Performance",
  "Error_deploy_version": "Tu version del Back Office no estaba actualizada. Pruebe de nuevo!",
  "eggs": "Huevos",
  "lupin": "Altramuz",
  "gluten": "Gluten",
  "sesame": "Sésamo",
  "mustard": "Mostaza",
  "fish": "Pescado",
  "celery": "Apio",
  "crustaceans": "Crustáceos",
  "peanuts": "Cacahuates",
  "molluscs": "Moluscos",
  "soy": "Soja",
  "dried fruit": "Frutos secos",
  "sulphites": "Sulfitos",
  "milk": "Lácteos",
  "Top Waiters": "Top Camareros",
  "Score": "Puntuación",
  "Waiter": "Camarero",
  "All": "Todos",
  Main_Image_OP: "Imagen de portada de Order & Pay",
  Created_allergens: "Alérgenos guardados",
  Error_creating_allergens: "Error guardando alérgenos",
  Error_deleting_allergens: "Error eliminando alérgenos",
  Deleted_allergens: "Alérgenos eliminados",
  "Select one option": "Selecciona una opción",
  "catalog": "Catálogo",
  modifier_name: "Nombre de modificador",
  modifier_description: "Descripción de modificador",
  modifier_category_name: "Categoría de modificador",
  product_name: "Nombre de producto",
  product_description: "Descripción de producto",
  product_category_name: "Categoría de producto",
  category_group_name: "Categoría secundaria",
  supergroup_name: "Categoría principal",
  "click to sync message": "<0>Sincroniza</0> la carta <2>en un solo click</2>",
  "Create shifts to be able to configure the stocks": "<0>Crea los turnos</0> en el restaurante para poder configurar el stock",
  "Edit_group": "Editar Grupo",
  "Error_saving_image": "Error guardando imagen",
  "Error deleting image": "Error eliminando imagen",
  "Updated_category": "Categoría actualizada",
  "Updated_group": "Grupo de categorías actualizada",
  "Swap to visibility_schedule": "Confirma si quiere cambiar la visibilidad a 'Horario Visibilidad' para usar sus horarios",
  "Created_time_range": "Horario creado",
  "Error_creating_time_range": "Error al crear rango de tiempo",
  "Updated_schedule": "Horario actualizado",
  "Error_updating_schedule": "Error al actualizar el intervalo de tiempo",
  "With no restaurants selected you cannot continue to navigate, please select at least one restaurant": "Sin seleccionar un restaurant no puedes seguir navegando. Por favor, selecciona al menos un restaurante",
  translations: "Traducciones",
  "product_highlight": "Destacado de productos",
  "highlights_banners": "Destacados y banners",
  "cross_selling": "Venta cruzada",
  Product: "Producto",
  product: "producto",
  products: "productos",
  product_plural: "productos",
  modifiers: "modificadores",
  "spanish": "Español",
  "catalan": "Catalán",
  "english": "Inglés",
  "french": "Francés",
  "add language": "Añadir idioma",
  "select language": "Seleccionar idioma",
  "yes":"Sí",
  "no":"No",
  "Program info": "Información programa",
  "Mail list": "Lista de correos",
  "New time range": "Nueva franja horaria",
  "enabled": "activo",
  "disabled": "inactivo",
  "create": "crear",
  "Delete program": "Eliminar programa",
  "% redeem": "Porcentaje de créditos",
  "min to redeem": "Consumo mínimo para redimir créditos",
  "min to earn": "Consumo mínimo para ganar créditos",
  "days and hours": "Días y horarios",
  "Current": "Actual",
  "total_payments": "Total pagos",
  "payments": "pagos",
  "loyalty_payments": "Pagos con loyalty",
  "percent_loyalty_payments": "% valor loyalty / total=%",
  "transactions": "Transacciones",
  "total_transactions": "Total transacciones",
  "loyalty_transactions": "Transacciones loyalty",
  "percent_loyalty_transactions": "% valor loyalty / total=%",
  "transactions_with_loyalty_per_user": "# transacciones por usuarios con loyalty",
  "users_who_repeated": "# usuarios que han repetido",
  "avg_ticket": "Ticket medio",
  "granted_credits": "Créditos total concedido",
  "used_credits": "Créditos usado",
  "unused_credits": "Créditos no usado",
  "an_error_has_occurred": "Se ha producido un error",
  "show only unfinished translations": "Traducciones incompletas",
  "no_translations_message":"Complete la carta con los nombres o descripciones que quiera que traducir",
  "show more": "Ver más",
  "tpv_name": "Nombre en el TPV",
  "tpv_description": "Descripción en el TPV",
  "tpv_value_placeholder": "No aplica - Categoría creada en Yumminn",
  "Customization": "Personalización",
  "button_color": "Color de botones",
  "accent_color": "Color complementario",
  "background_color": "Color de portada",
  "refunded":"Reembolsado",
  "see here": "Ver aquí",
  "Sector": "Sector",
  "Method": "Método",
  "register payment on tpv": "Ingresar manualmente en el TPV",
  "pos": "sistema punto de venta",
  "payment charged":"Pago cobrado",
  "UE Commission": "Comisión UE",
  "Not UE Commission": "Comisión no europea",
  "latitude": "Latitud",
  "longitude": "Longitud",
  "invalid_value": "Valor inválido",
  "logo_size_changed": "Tamaño de logo actualizado",
  "color_changed": "Color actualizado",
  "logo_size": "Tamaño relativo de logo",
  "optional": "opcional",
  "Orders activated": "Los productos de la webapp están disponibles para pedidos de comensales",
  "Orders deactivated": "Los productos no están disponibles para realizar pedidos por parte del comensal, a través de la webapp",
  "Toggle orders with click": "{{toggle}} pedidos con un click",
  "Activate": "Activar",
  "Deactivate": "Desactivar",
  "activate": "activar",
  "deactivate": "desactivar",
  "Apple users": "Usuarios Apple",
  "Enabled": "Activos",
  "Disabled": "Inactivos",
  "Users with payments": "Usuarios con pagos",
  prev_clients_who_repeated: "Recurrentes previos",
  prev_clients_who_payed_just_once: "Nuevos previos",
  clients_who_repeated: "Recurrentes",
  clients_who_payed_just_once: "Nuevos",
  clients_who_repeated_actual: "Recurrentes",
  clients_who_repeated_prev: "Recurrentes previo",
  clients_who_payed_just_once_prev: "Nuevos previo",
  hide_difference: "Ocultar diferencia",
  view_difference: "Ver diferencia",
  "Refunded amount": "Reembolsado",
  "partially_refunded": "Reembolsado parcialmente",
  home_customization: "Personalización de inicio",
  "Go to shift tab": "Ir a sección de turnos",
  preview: "Vista previa",
  logo: "Logo",
  size: "Tamaño",
  home_image: "Foto o gif de fondo",
  overlay: "Sombra",
  customize_webapp: "Personaliza la webapp",
  customize_landing: "Personaliza la landing",
  color_button: "Color de botones",
  color_secondary: "Color secundario",
  color_primary: "Color principal",
  color_cover: "Color de portada",
  colors: "Colores",
  cards_content: "Contenido de las tarjetas",
  edit_each_restaurant_card: "Edita la tarjeta de cada restaurante",
  see_compressed: "Ver comprimido",
  see_expanded: "Ver completo",
  title: "Titulo",
  splash_design: "Diseño de portada",
  title: "Titulo",
  background_design: "Diseño del fondo",
  menu_design: "Diseño de la carta",
  restaurant_image: "Foto de restaurante",
  secondary_logo: "Logo secundario",
  suggestion_logo: "Sugerencias: En color blanco y sin fondo (png)",
  opcional: "opcional",
  hidden: "Oculto",
  cover: "Portada",
  home: "Inicio",
  changes_applied: "Cambios aplicados",
  menu_button_label: "Mira la carta",
  pay_button_label: "Ver cuenta y pagar",
  table: "Mesa",
  at_table: "En mesa",
  take_away: "Para recoger",
  delivery: "Delivery",
  menu: "Carta",
  payment: "Pago",
  reward: "Recompensa",
  contrast_good: "Contraste correcto",
  contrast_minimum: "Contraste mínimo",
  contrast_bad: "Contraste insuficiente",
  "average": "Promedio",
  "tips": "propinas",
  "users": "usuarios",
  "active_users": "usuarios activos",
  "new": "Nuevos",
  "recurrent": "Recurrentes",
  "no_recurrent": "No recurrentes",
  "users_who_paid": "Usuarios que han pagado",
  "payment_rates_per_user": "Porcentaje de pagos con ususario registrado",
  "cashback_granted": "Cashback generado",
  "cashback_redeemed": "Cashback redimido",
  "points_granted": "Puntos generados",
  "total_reviews": "Nº de valoraciones totales",
  "num_reviews": "Nº de valoraciones",
  "average_stars": "Promedio",
  "average_ticket": "Ticket medio",
  "average_tip": "Media Propinas",
  "accumulated": "Acumulado",
  "today": "Hoy",
  "last_week": "Semana pasada",
  "last_month": "Mes pasado",
  "This year": "Este año",
  "Last year": "Año pasado",
  "All time": "Todo el tiempo",
  "payments_period": "Pagos del periodo",
  "total_period": "Total del periodo",
  "last_period": "Último periodo",
  "payments_with_tip": "Pagos con propina",
  "tip_on_payment": "Propina sobre pagos",
  "info_new": "Usuarios registrados",
  "info_recurrent": "Usuarios registrados que han hehcho más de un pago",
  "info_users_who_paid": "Usuarios registrados que han hehcho uno o más pagos",
  "info_payment_rates_per_user": "Porcentajes pagos por usuario",
  "info_cashback_granted": "Total de cashback generado",
  "info_cashback_redeemed": "Cashback utilizado en pagos",
  "info_points_granted": "Total de puntos generado",
  "info_total_reviews": "Total de reseñas en todos los restaurantes",
  "info_average_stars": "Valoración promedio de todos los restaurantes",
  "info_today": "Total de pagos de hoy",
  "info_last_week": "Total de pagos de la semana en curso",
  "info_last_month": "Total de pagos del mes en curso",
  "info_accumulated": "Total de pagos con Yumminn",
  total_clients_registered: "Total clientes registrados",
  client: "Cliente",
  "Total registered on restaurant": "Total registrados en restaurante",
  restaurants_with_cashback: "Restaurantes con cashback",
  avg_cashback_per_user: "Media de cashback por usuario",
  avg_points_per_user: "Media de puntos por usuario",
  "info": "Info",
  "SUPER_GROUP": "Categorías principales",
  "CATEGORY_GROUP": "Categorías secundarias",
  "CATEGORY": "Categorías de producto",
  "Create_SUPER_GROUP": "Nueva categoría principal",
  "Create_CATEGORY_GROUP": "Nueva categoría secundaria",
  "Create_CATEGORY": "Nueva categoría de producto",
  "Edit_SUPER_GROUP": "Editar categoría principal",
  "Edit_CATEGORY_GROUP": "Editar categoría secundaria",
  "Edit_CATEGORY": "Editar categoría de producto",
  "Info_SUPER_GROUP": "Crea hasta 3 categorías principales, para facilitar la navegación del comensal",
  "Info_CATEGORY_GROUP": "Crea categorías secundarias, para agrupar tus productos",
  "Info_CATEGORY": "Crea categorías, para agrupar tus productos",
  "name_SUPER_GROUP": "Nombre de la categoría principal",
  "name_CATEGORY_GROUP": "Nombre de la categoría secundaria",
  "name_CATEGORY": "Nombre de la categoría de producto",
  "name_schedule": "Nombre de horario",
  "select_children_SUPER_GROUP": "Selecciona las categorías secundarias",
  "select_children_CATEGORY_GROUP": "Selecciona las categorías de producto",
  "select_children_CATEGORY": "Selecciona los productos",
  "web_preview": "Previsualización en la web",
  "define_schedule": "Definir horario",
  "every_day": "Todos los días",
  "weekend": "Fines de semana",
  "mon": "Lun",
  "tue": "Mar",
  "wed": "Mié",
  "thu": "Jue",
  "fri": "Vie",
  "sat": "Sáb",
  "sun": "Dom",
  "from": "desde",
  "from_(short)": "de",
  "Ongoing shift": "Turno en curso",
  "Oncoming shift": "Próximo turno",
  "select_days": "Selecciona los días",
  "add_time_range": "Añadir otra franja horaria",
  "you_have_unsaved_changes": "Tienes cambios sin guardar",
  "warning_message_unsaved_changes": "Si cierras esta ventana, perderás los cambios realizados.",
  "keep_editing": "Seguir editando",
  "close": "Cerrar",
  "group": "Grupo",
  "category": "Categoría",
  "schedule": "Horario",
  "specs": "Especificaciones",
  "visualize": "Visualizar",
  "schedules": "Visibilidad y horarios",
  "explanation_schedules_active": "Todas las franjas (día/hora) de todos los horarios activos se <1>complementarán</1>. Si deseas ocultar una franja asegúrate de que la misma no figure en ningún horario activo.",
  "explanation_schedules_inactive": "La categoría <1>no está visible</1> en este momento. Para que tus comensales puedan visualizarla, actívala en la parte superior derecha de este panel.",
  "explanation_schedule_SUPER_GROUP": "<0>Por defecto</0>, este horario <2>se aplicará a todas las categorías secundarias y de producto</2> que se encuentren dentro de esta categoría principal. Puedes modificar las <4>excepciones</4> editando la categoría correspondiente.",
  "explanation_schedule_CATEGORY_GROUP": "<0>Por defecto</0>, este horario <2>se aplicará a todas las categorías de producto</2> que se encuentren dentro de esta categoría. Puedes modificar las <4>excepciones</4> editando la categoría correspondiente.",
  "explanation_schedule_CATEGORY": "<0>Por defecto</0>, este horario <2>se aplicará a todos los productos</2> que se encuentren dentro de esta categoría. Puedes modificar las <4>excepciones</4> editando el producto correspondiente.",
  "explanation_disabled_select_SUPER_GROUP": "<0>Para sumar o quitar categorías secundarias</0> a esta categoría principal, <2>arrástralas desde el</2> <4>panel general de la carta</4>.",
  "explanation_disabled_select_CATEGORY_GROUP": "<0>Para sumar o quitar categorías de producto</0> a esta categoría secundaria, <2>arrástralas desde el</2> <4>panel general de la carta</4>.",
  "explanation_disabled_select_CATEGORY": "<0>Para sumar o quitar productos</0> a esta categoría, <2>arrástralos desde el</2> <4>panel general de la carta</4>.",
  "title_empty_schedules_active": "La categoría estará <1>visible</1> durante todo tu horario de apertura",
  "title_empty_schedules_inactive": "La categoría <1>no está visible</1> en este momento",
  "explanation_empty_schedules_active": "Si deseas personalizar los momentos de visibilidad, puedes <1>definir un horario o desactivar la categoría</1> en la parte superior derecha.",
  "explanation_empty_schedules_inactive": "Para que tus comensales puedan visualizarla, <1>actívala</1> en la parte superior derecha de este panel.",
  forgot_password_text: "Recuperar contraseña",
  forgot_email_sent: "Revisa tu correo electrónico para obtener instrucciones",
  forgot_password_explanation: "Ingresa la dirección de correo electrónico utilizada para registrarte, y si está en nuestro sistema, te enviaremos un correo con los pasos a seguir.",
  send_reset_link: "Enviar enlace",
  payments_per_restaurant: "Pagos por restaurante",
  with_payments: "Con pagos",
  restaurants_with_reviews: "Restaurantes con reseñas",
  show_percentages: "Mostrar porcentajes",
  "show_totals": "Mostrar totales",
  "current_week": "<1>Semana</1> en curso",
  "current_month": "<1>Mes</1> en curso",
  "current_year": "<1>Año</1> en curso",
  total_week: "Total semana",
  total_month: "Total mes",
  avg_payments_with_cashback: 'Media de pagos con cashback',
  new_users: 'Nuevos usuarios',
  recurrent_users: "Usuarios recurrentes",
  payments_with_review: 'Pagos con reseña',
  registered_users: 'Usuarios registrados',
  tables_and_sectors: 'Mesas y Sectores',
  categories_and_products: 'Categorías y productos',
  my_solution: 'Mi solución',
  metrics: 'Métricas',
  'You dont have this functionality message' : "¡Mejora la <1>eficiencia</1>, tu ticket medio y la experiencia de tus comensales con la funcionalidad de <3>Pedir</3>!",
  Contact_our_team: "Contacta con nuestro equipo",
  'Payments and orders': 'Pagos y pedidos',
  order: "pedido",
  orders: "pedidos",
  waiter: "camarero",
  waiters: "camareros",
  restaurants: "Restaurantes",
  Restaurant: "Restaurante",
  ratings: "Valoraciones",
  synchronize: "Sincronizar",
  "current": "actual",
  "previous": "previo",
  "total_year": "Total anual",
  "historic_total": "Total histórico",
  "without_payments": "Sin pagos",
  "users_with_payments": "usuarios con pagos",
  "granted": "generado",
  "redeemed": "redimido",
  email_address: "Correo electrónico",
  customer_name: "Nombre del cliente",
  pay_with_qr: "Pagar con QR",
  Highlight_products: "Destacado de productos",
  discounts: "Descuentos",
  discount: "descuento",
  promotions: "Promociones",
  promotion: "Promoción",
  coupons: "Cupones",
  coupon: "Cupón",
  add: "Añadir",
  promo_explanation_title: "¿Cómo funcionan las promociones?",
  discount_name: "Nombre del descuento",
  texts: "Textos",
  typology: "Tipología del descuento",
  discount_value: "Valor del descuento",
  applied_for: "Aplicado en",
  example_discount_title: "¡Toda la carta a -5%!",
  example_general_discount_marquee_text: "!Toda la carta a -5%!",
  example_discount_name: "Descuento general 5%",
  example_general_discount_payment_details_text: "Descuento general 5%",
  example_code_coupon_payment_details_text: "Cupón de descuento 5%",
  name_discount: "Nombre descuento (interno)",
  marquee_text: "Texto de la marquesina en la carta",
  payment_details_text: "Texto en detalles de pago",
  title_discount: "Título de la marquesina en la carta",
  discount_typology: "Tipología del descuento",
  discount_type: "Tipo de descuento",
  select_type_promotion: "¿Qué modalidad de descuento quieres aplicar?",
  example_discount_value: 5,
  percentage: "Porcentaje",
  cupon_code: "Código del cupón",
  whole_catalog_discount: "Toda la carta",
  code_coupon_discount: "Código de descuento",
  creating: "Creando",
  coupon_message: "¡Los clientes leales no sólo regresan y te recomiendan, insisten en que sus amigos vayan a su restaurante!",
  edit_discount: "Editar descuento",
  create_discount_for_the_whole_catalog: "¡Crea descuentos en toda la carta!",
  promo_explanation_step_one: "<0>Crea una promoción</0> completando los campos del formulario. <2>Añádele un título atractivo y claro.</2>",
  promo_explanation_step_two: "Automáticamente se creará un <1>banner (en promociones)</1> o un <3>selector de cupones</3>, para darle visibilidad a tus comensales.",
  promo_explanation_step_three: "El descuento se aplicará en el <1>total de la cuenta de tu comensal.</1>",
  promo_explanation_step_four: "Puedes <1>activarlo o desactivarlo</1> cuando necesites, así como <3>modificarlo</3> desde el panel de <5>“tus descuentos”</5>.",
  ready: "Listo",
  discount_info_box: '¡Próximamente podrás aplicar descuentos en productos!',
  product_promotion: 'Descuentos por producto',
  tbd: '(próximamente)',
  coupons_discounts: 'Cupones de descuento',
  base_amount: "Monto base",
  promos: "Promos/Créditos",
  total_base_amount: "Total de monto base",
  total_amount: "Volumen de pagos",
  payment_amount: "Cantidad de pagos",
  remove_category: "Eliminar categoría",
  no_discount_added: "Ningún descuento añadido",
  are_you_sure_to_remove: "¿Estás seguro de que quieres eliminar esta categoría?",
  "no, cancel": "No, cancelar",
  "yes, remove": "Sí, eliminar",
  "total_bill": "Total de la cuenta",
  do_you_want_to_remove_this_category: "¿Quieres eliminar esta categoría?",
  how_to_remove_category: "Para eliminar la categoría, asegúrate de mover tus productos a otras categorías",
  alright: "De acuerdo",
  coupon_code: "Código del cupón",
  example_coupon_code: "Ejemplo: 'Super40'",
  payment_details: "Detalles de pago",
  subtotal: "Subtotal",
  tip: "Propina",
  apply_coupon: "Aplicar cupón",
  do_you_have_a_code_coupon: "¿Tienes un cupón de descuento?",
  continue: "Continuar",
  create_coupon: "Crear cupón",
  create_discount: "Crear descuento",
  discount_info: "Información",
  period: "Periodo",
  delivery_color: "Color delivery",
  takeaway_color: "Color takeaway",
  products_and_categories: "Productos y categorías",
  link_and_pdf: "Link y PDF",
  activate_orders: "Activar pedidos",
  yesterday: "Ayer",
  this_week: "Esta semana",
  last_7_days: "Ult. 7 días",
  last_30_days: "Ult. 30 días",
  this_month: "Mes actual",
  add_new: "Añadir nuevo",
  add_new_m: "Añadir nuevo",
  add_new_f: "Añadir nueva",
  see_image: "Ver imagen",
  Updates: "Novedades",
  "your week in yumminn": "Tu <1>semana</1> en Yumminn",
  "maximize your solution": "<0>Potencia al máximo</0> tu solución",
  see_all: "Ver todas",
  time_saved_for_waiters: "Tiempo ahorrado a camareros",
  time_saved_for_diners: "Tiempo ahorrado a comensales",
  total_payments_of_the_last_30_days: "Total de pagos de los últimos 30 días",
  new_users_of_the_last_30_days: "Usuarios nuevos de los últimos 30 días",
  new_reviews_of_the_last_30_days: "Reseñas nuevas de los últimos 30 días",
  general_metrics: "Métricas generales",
  product_metrics: "Métricas de producto",
  edit_menu: "Editar la carta",
  add_image: "Subir imagen",
  change_image: "Cambiar imagen",
  subtitle: "Subtítulo",
  title_subtitle: "Título y subtítulo",
  write_title: "Escribe un título",
  write_subtitle: "Escribe un subtítulo",
  design: "Diseño",
  restaurant_group: "grupo de restaurantes",
  home_restaurant_group: "home para grupo de restaurantes",
  home_delivery_takeaway: "home para delivery-takeaway",
  by_default_restaurant_name: "Por defecto estará el nombre del restaurante",
  Edit_product: "Editar producto",
  see_dashboard: "Ver métricas generales",
  last_30_days_with_yumminn: "Últimos 30 días con tu solución Yumminn",
  products_list: "Listado de productos",
  add_new_product: "Añadir nuevo producto",
  title_empty_category: "Esta categoría está vacía",
  message_empty_category: "Crea un nuevo producto o agrégalos desde otras categorías",
  Create_product: "Crear producto",
  Deleted_supergroup: "Categoría principal eliminada",
  Deleted_group: "Categoría secundaria eliminada",
  Deleted_category: "Categoría de producto eliminada",
  Deleted_product: "Producto eliminado",
  Deleted_modifier: "Modificador eliminado",
  remove_product: "Eliminar producto",
  Shift: "Turno",
  "All items": "Todos los ítems",
  "With limit": "Con límite",
  "Without limit": "Sin límite",
  "Filter by stock": "Filtrar por stock",
  are_you_sure_to_remove_product: "¿Estás seguro de que quieres eliminar este producto?",
  info_visibility_switch_disabled: "Las categorías vacías no aparecerán en el menú",
  remove_category_not_allowed: "No es posible eliminar esta categoría",
  title_empty_menu: "Esta carta está vacía",
  message_empty_menu: "Comienza por crear una categroría principal para facilitar la navegación del comensal",
  creating_product_title: "Creando producto",
  creating_product_message: "Pronto tus clientes disfrutarán de esta nueva propuesta gastronómica",
  theme: 'Tema',
  light: 'Claro',
  red: 'Rojo',
  burgundy: 'Borgoña',
  dark: 'Oscuro',
  blue: 'Azul',
  blue_linear: 'Azul lineal',
  superdark: 'Negro',
  superdark_linear: 'Negro lineal',
  your_logo: 'Tu logo',
  copy_button_color: 'Copiar color de los botones',
  creating_catalog_title: 'Creando catálogo',
  creating_catalog_message: 'Estamos creando las primeras categorías para tus productos',
  no_orders_yet: "Aún no tienes pedidos",
  create_discounts_in_your_menu: "¡Crea descuentos en tu carta!",
  content: "Contenido",
  highlight_name: "Nombre del destacado",
  create_new_featured_product: "Crear nuevo destacado",
  create_advertising_banner: "Crear banner",
  increase_your_average_ticket: "<0>¡Incrementa tu ticket medio <1>destacando</1></0> tus productos!",
  example_minimum_redeem_amount: "Ejemplo: 25€",
  minimum_redeem_amount: "Importe mínimo para aplicar el descuento",
  cancel_booking: "Cancelar reserva",
  cancel_booking_confirmation: "¿Estás seguro que quieres cancelar esta reserva?",
  cancel_booking_message: "Si deseas informar el motivo de cancelación al cliente, <1>escríbelo aquí</1> y lo sumaremos al mail de cancelación que le enviaremos.",
  cancel_reason: "Razón de cancelación",
  no_keep: "No, mantener",
  yes_cancel: "Sí, cancelar",
  background_image: "Imagen de fondo",
  Order_scheduling: "Programación de pedidos",
  diners_amount: "Cantidad de comensales",
  date: "fecha",
  hour: "hora",
  compulsory_fields: "Campos obligatorios",
  compulsory_name_error: "Por favor, introduce un nombre",
  compulsory_email_error: "Por favor, introduce un email válido",
  compulsory_date_error: "Por favor, introduce una fecha válida",
  compulsory_hour_error: "Por favor, escoge una hora en el selector",
  compulsory_diners_error: "Por favor, selecciona el número de comensales",
  lockers: "lockers",
  add_locker: "Crear locker",
  select_order: "Seleccionar pedido",
  create_locker: "Crear locker",
  edit_locker: "Editar locker",
  locker: "Locker",
  counter: "Mostrador",
  key: "Clave",
  no_key: "Sin clave",
  create_locker_error: "Ha ocurrido un error al crear el locker",
  edit_locker_error: "Ha ocurrido un error al actualizar el locker",
  delete_locker_error: "Ha ocurrido un error al eliminar el locker",
  order_locker_error: "Ha ocurrido un error al asignar el pedido",
  confirm_cancel_locker_title: "¿Quieres descartar los cambios?",
  changes_will_be_lost: "Perderás todos los cambios realizados",
  confirm_delete_locker_title: "¿Quieres eliminar el locker?",
  this_cannot_be_undone: "Esta acción no se puede deshacer",
  with_key: "Con clave",
  without_key: "Sin clave",
  order_management: "Gestión de pedidos",
  see_order_management: "Ver gestión de pedidos",
  orders_in_lockers: "Pedidos en lockers",
  orders_in_counter: "Pedidos en mostrador",
  new_orders: "Pedidos nuevos",
  empty_lockers: "Lockers vacíos",
  active_lockers: "Lockers activos",
  shift_time_left: "restantes del turno",
  orders_received: "Pedidos recibidos",
  orders_assignables: "Pedidos asignables",
  orders_in_locker: "Pedidos en lockers",
  assign_to_locker: "Asignar a locker",
  see_and_manage: "Ver detalles y gestionar",
  promised_delivery_time: "Entrega prometida",
  products: "Productos",
  comment: "Comentario",
  no_comments: "Sin comentarios",
  total_account: "Total de la cuenta",
  no_active_shift: "Actualmente no hay ningún turno activo",
  manage_order_title: "¿Qué quieres hacer<1/><2>con el pedido {{code}}</2>?",
  confirm_pickup_title: "¿El pedido anterior<1/><2>ya ha sido retirado</2>?",
  select_an_option: "Seleccione una opción",
  withdrawn: "Ya fue retirado",
  to_locker: "Colocar en locker",
  to_counter: "Llevar a {{counter_name}}",
  to_kitchen: "Vuelve a cocina",
  remove_from_counter: "Retirar de {{counter_name}}",
  yes_withdrawn: "Sí, ya fue retirado",
  no_to_counter: "No, llevar a {{counter_name}}",
  no_to_kithcen: "No, vuelve a cocina",
  send_reminder: "Enviar recordatorio a cliente",
  error_manage_order: "Ha ocurrido un error al gestionar el pedido",
  clear: "vaciar",
  see_order: "ver pedido",
  sort_by: "ordenar por",
  newest: "Más reciente",
  closer: "Más cercana",
  farthest: "Más lejana",
  oldest: "Más antiguo",
  pickup_time: "hora de recogida",
  all_slots: "Todos los slots",
  clear_locker: "Vaciar locker",
  clear_all_lockers: "Vaciar todos los lockers",
  clear_all_lockers_title: "Vaciar todos los lockers",
  clear_all_lockers_message: "Al continuar se <1>vaciarán todos los lockers</1> y se marcarán todos los pedidos como entregados. ¿Estás seguro que quieres continuar?",
  replace_order: "Reemplazar pedido",
  pickup_success_locker: "¡Listo! Se ha retirado el pedido del locker",
  pickup_success_counter: "¡Listo! Se ha retirado el pedido del mostrador",
  move_order_success: "¡Listo! Se han realizado los cambios en el locker",
  move_to_locker_success: "¡Listo! Se ha colocado el pedido en {{locker}}",
  email_reminder_success: "¡Listo! Se ha enviado un recordatorio al cliente",
  email_reminder_message: "Y se ha enviado un correo recordatorio al cliente:",
  ok_close: "Ok, salir",
  yes_continue: "Sí, continuar",
  no_cancel: "No, cancelar",
  cross_sell_title: "Configuración de tu venta cruzada",
  select_products: "Selecciona los productos",
  at_least_four_products: "⚠️ Selecciona al menos 4 productos",
  order_products_dnd: "✏️​ Ordena los productos arrastrando y soltando",
  client_sees_products_before_payment: "El comensal visualizará la venta cruzada justo <1>antes de completar su cesta</1>",
  see_in_app: "<0>Visualizar</0> en la webapp",
  cross_sell_no_products : "No se ha encontrado ningún producto para poder realizar venta cruzada.<1/> Debe sincronizar la carta.",
  update: "Actualizar",
  tutorials: "tutoriales",
  connect_to_our_wifi: "Conéctate a nuestro wifi",
  copy: "Copiar",
  text_copied: "Texto copiado",
  wifi_pass_config_message: "Ve a <1>⚙️ configuración de wifi</1> y pega la contraseña para conectarte.",
  wifi_config_message: "Ve a <1>⚙️ configuración de wifi</1> para conectarte a nuestra red.",
  wifi_name: "Nombre de la red",
  wifi_pass: "Contraseña de la red",
  wifi_name_placeholder: "Escribe el nombre de la red",
  wifi_pass_placeholder: "Escribe la contraseña de la red",
  wifi_preview_message: "Si el comensal tiene <1>una conexión débil</1>, verá automáticamente los datos del wifi al escanear el QR.<3/><4/>Además, podrá acceder manualmente desde el icono superior izquierdo",
  delete_wifi_title: "¿Quieres eliminar la red wifi?",
  delete_wifi_message: "No se mostrará el aviso cuando la conexión sea débil ni aparecerá el icono para ver la red",
  wifi_save_success: "Red wifi guardada",
  wifi_save_error: "Ha ocurrido un error al guardar la red",
  credit_cents: "Créditos",
  manage: "gestionar",
  "kitchen_saturated?": "¿Cocina saturada?",
  kitchen_saturated: "Cocina saturada",
  kitchen_saturated_delay: "Tiempo de espera <1>activo</1>",
  kitchen_saturated_pause: "Pedidos en <1>pausa</1>",
  remaining: "quedan",
  select_and_save: "Selecciona una opción y guarda",
  how_long_delay: "¿Cuánto <1>tiempo de espera</1> hay?",
  how_long_report: "¿<1>Durante cuánto tiempo</1> le informaremos al comensal?",
  how_long_pause: "¿<1>Durante cuánto tiempo</1> quieres pausar los pedidos?",
  orders_paused: "El comensal podrá ver la carta, pero no podrá realizar el pedido durante este tiempo.",
  clients_will_be_inform_in_webapp: "<0>Le informaremos al comensal</0> a través de la webapp",
  clients_will_be_inform_in_webapp_short: "<0>El comensal será informado</0> en la webapp",
  report_delay: "<0>Informar</0> tiempo de espera",
  pause_orders: "<0>Pausar</0> pedidos",
  minutes: "minutos",
  minute: "minuto",
  undefined: "indefinido",
  select_restaurant: "Selecciona un restaurante",
  active_saturation_restaurants: "<0>{{count}}</0> Restaurante con <2>gestión de cocina saturada activa</2>",
  active_saturation_restaurants_plural: "<0>{{count}}</0> Restaurantes con <2>gestión de cocina saturada activa</2>",
  "Delivery and takeaway": "Delivery y takeaway",
  "ETA_explanation": "Es el <1>tiempo de espera</1> que <br>comunicaremos al cliente. Incluye <br><3>la preparación del pedido y el envío</3>.",
  radius: "Radio",
  geojson: "Geojson",
  delivery_time: "Tiempo de entrega",
  "delivery area": "Zona de entrega", 
  "shipping cost": "Coste de envío",
  "delivery fee": "gastos de envío",
  minimum_shipping_cost: "Coste de envío mínimo",
  cost_per_km: "Coste por kilómetro",
  estimated_delivery_time: "Tiempo estimado de entrega",
  type_of_delivery_area: "Tipo de zona de entrega",
  radius_in_meters: "Radio en metros",
  upload_geojson: "Sube un archivo geojson", 
  upload_file: "Subir archivo",
  map_linked_successfully: "Mapa enlazado correctamente",
  modify_file: "Modificar archivo",
  how_to_create_geojson: "¿Cómo crear un geojson?",
  how_to_obtain_coordinates: "¿Cómo obtener las coordenadas de la <br>dirección de mi restaurante?",
  error_updating_delivery: "Error al actualizar la información de delivery",
  "takeaway": "Takeaway",
  delete_file: "Eliminar archivo",
  "No file selected": "Archivo no seleccionado",
  "Invalid file format. Please select a .geojson file.": "Formato de archivo inválido. Por favor, seleccione un archivo .geojson.",
  saturation_updated: "Estado de la cocina actualizado",
  saturation_error: "Ha ocurrido un error al actualizar el estado de la cocina",
  saturation_end_delay: "Tiempo de espera <1>desactivado</1><2> en {{restaurant}}</2>",
  saturation_end_pause: "Pedidos nuevamente <1>activos</1><2> en {{restaurant}}</2>",
  time_finished: "Tiempo Finalizado",
  Purchase: 'Compra',
  purchase: 'compra',
  purchase_plural: 'compras',
  ticket: 'entrada',
  ticket_plural: 'entradas',
  validated: 'Validada',
  validate: 'validar',
  cancel_validation: 'cancelar validación',
  event: 'Evento',
  filter_by_status: 'Filtrar por estado',
  tickets_purchased: '<0>{{count}} Entrada</0> comprada',
  tickets_purchased_plural: '<0>{{count}} Entradas</0> compradas',
  tickets_to_validate: 'Entrada para validar',
  tickets_to_validate_plural: 'Entradas para validar',
  validated_tickets: 'Entrada validada',
  validated_tickets_plural: 'Entradas validadas',
  entry: 'entrada',
  entry_plural: 'entradas',
  error_updating_validation: 'Ha ocurrido un error al validar la entrada',
  searching: 'buscando',
  no_results: 'sin resultados',
  mail: 'correo',
  mail_plural: 'correos',
  '#purchase': '#Compra',
  '#ticket': '#Entrada',
  placeholder_search_tickets: '#Compra, #Entrada, Teléfono, Correo, o Nombre',
  shop: 'local',
  no_tickets_yet: "<0>Aún no tienes entradas</0> para gestionar",
  no_events_yet: "<0Aún no tienes eventos/0> para gestionar",
  ticket_validation: "Validación de tickets",
  create_events: "Crear eventos",
  "name of the event": "Nombre del evento",
  "create_your_tickets": "Crea las entradas de tu evento", 
  add_ticket: "Añadir entrada",
  create_event: "Crear evento",
  photo_suggestions: "<0>Sugerencias:</0> png/jpg o gif de 2MB máx de peso.",
  "write here": "Escribe aquí",
  "translate full catalog": "Traducir toda la carta",
  "default language": "Idioma por defecto",
  edit_event: "Editar evento",
  do_not_forget_to_add_tickets: "¡No te olvides de añadir las entradas!",
  creating_event: "Creando evento",
  create_ticket: "Crear entrada",
  explanation_ticket_image: "<0>Por defecto</0>, en la webapp se verá un <2>icono de entrada</2>, pero puedes añadir una imagen aquí.",
  suggestions_ticket: "<0>Sugerencias</0>: en color blanco y sin fondo (png) de 2MB máx.",
  "name of the ticket": "Nombre de la entrada",
  price: "Precio",
  event_created: "Evento creado",
  ticket_created: "Entrada creada",
  event_updated: "Evento actualizado",
  event_deleted: "Evento borrado",
  ticket_updated: "Entrada actualizada",
  ticket_deleted: "Entrada borrada",
  'Error_creating_event': "Error creando evento",
  'Error_updating_event': "Error actualizando evento",
  'Error_deleting_event': "Error borrando evento",
  'Error_saving_ticket': "Error guardando entrada",
  'Error_updating_ticket':"Error actualizando entrada",
  'Error_deleting_ticket': "Error borrando entrada",
  drag_or_click_here: "Arrastra o haz clic aquí",
  to_upload_image: "para subir una imagen",
  edit_ticket: "Editar entrada",
  see_in_webapp: "Ver en la webapp",
  yes_delete: "Sí, eliminar",
  delete_event: "Eliminar evento",
  delete_event_question: "¿Estás seguro de que quieres eliminar este evento?",
  delete_ticket: "Eliminar entrada",
  delete_ticket_question: "¿Estás seguro de que quieres eliminar esta entrada?",
  you_cannot_remove_this_event: "No se puede eliminar este evento",
  you_cannot_remove_this_ticket: "No se puede eliminar esta entrada",
  not_deletable_event_explanation: "Ya se han vendido entradas para este evento. Puedes desactivarlo para dejar de mostrarlo en la webapp.",
  not_deletable_ticket_explanation: "Ya se han venido estas entradas. Puedes desactivar la entrada para dejar de mostrarla en la webapp",
  german: 'Alemán',
  italian: 'Italiano',
  portuguese: 'Portugués',
  download_qr: 'Descargar QR',
  see_event_at_webapp: "Ver evento en la webapp",
  scan_or_copy_link: "Escanea o copia el enlace",
  explanation_event_qr: "<0>Visualiza todos los eventos creados</0> en la webapp copiando el enlace o escaneando el QR.",
  create_your_first_event: "<0>Crea nuevos eventos</0><br> y vende entradas a través de Yumminn.",
  creating_ticket: "Creando entrada",
  wait_some_seconds: "Espera unos segundos", 
  desactivated_event: "Evento desactivado", 
  desactivated_ticket: "Entrada desactivada", 
  not_visible_at_webapp: "Ya no es visible para los clientes.",
  allergens_and_tags: 'Alérgenos y etiquetas',
  tags: 'Etiquetas',
  tags_explanation: 'Aplica etiquetas a tus productos para que tus comensales distingan rápidamente sus preferencias.',
  Vegetarian: "Vegetariano",
  Vegan: "Vegano",
  GlutenFree: "Sin gluten",
  Kosher: "Kosher",
  Halal: "Halal",
  Spicy1: "Picante",
  Spicy2: "Picante",
  Spicy3: "Picante",
  no_description: "Sin descripción", 
  copied: 'Copiado',
  "revision mode": "Modo revisión",
  "confirm and update": "Confirmar y actualizar",
  "cancel all changes": "Cancelar todos los cambios",
  "auto_translation": "Traducciones automáticas",
  "manual_translation": "Traducciones manuales",
  "remove_translations_in": "Eliminar las traducciones en",
  "warning_message_remove_translations_in": "¿Estás seguro que quieres eliminar las traducciones de la carta en",
  "translations done!": "¡Genial! Ya tienes tus traducciones",
  "translations_done_message": "Puedes revisarlas y ajustarlas manualmente. Deberás confirmar los cambios para que se reflejen en la webapp.",
  "we_are_on_it_message": "Estamos en ello, espera unos segundos...",
  "Modifier": "Modificador",
  "checking_translations_dimensions": "Estamos calculando el número de traducciones a realizar...",
  modifier_group: "Grupo de modificadores",
  modifier_list: "Listado de modificadores",
  add_modifier: "Añadir modificador",
  understood: "Entendido",
  delete_modifier_category: "Eliminar grupo de modificadores",
  delete_modifier_category_explanation: "Para eliminar un grupo de modificadores, primero debes eliminar sus modificadores.",
  confirm_delete_modifier_category: "¿Estás seguro que quieres eliminar este grupo?",
  delete_modifier: "Eliminar modificador",
  confirm_delete_modifier: "¿Estás seguro que quieres eliminar este modificador?",
  modifiers_empty_state: "<0>¡Crea modificadores</0> y haz que tus productos sean personalizables!",
  creating_modifier_title: "Creando modificador",
  creating_modifier_message: "Pronto tus clientes disfrutarán de productos totalmente personalizados",
  product_modifiers: "Modificadores de producto",
  add_discount: "Añadir descuento",
  what_type_of_discount_you_want_to_create: "¿Qué tipo de descuento quieres crear?",
  write_here_your_code: "Escribe aquí tu código",
  "discount in products": "Descuento en productos",
  "discount in all menu (promotion)": "Descuento en toda la carta (promoción)",
  coupon_discount: "Cupón de descuento",
  what_type_of_highlight_you_wanna_create: "¿Qué tipo de destacado quieres crear?",
  product_preview: "Previsualización de un producto",
  create_discount_in_products: "Crear descuento en productos",
  "select one or several products": "Selecciona uno o varios productos",
  select: "Seleccionar",
  discount_in_products: "descuento en productos",
  product_discount: "Descuento sobre producto/s",
  discount_already_applied: "¡Ya tienes el descuento aplicado y puedes verlo en la carta!",
  scan_the_qr: "Escanea el QR",
  products_and_specs: "Productos y especificaciones",
  "discountTitlePercentage": "-{{value}}% en {{count}} {{productCount}}",
  "discountTitleAmount": "-{{value}}€ en {{count}} {{productCount}}",
  "Applied in" : "Aplicado en",
  "product_sg": "producto",
  "products_pl": "productos",
  delete_discount_question: "¿Estás seguro de que quieres eliminar este descuento?",
  delete_discount: "Eliminar descuento",
  general_discount_already_active_message: "Ya tienes un descuento en toda la carta activo (promoción). Desactívalo para poder crear un descuento en productos.",
  product_discount_already_active_message: "Ya tienes un descuento sobre productos activo. Desactívalo para poder crear un descuento en toda la carta.",
  not_possible_to_change_status: "No es posible cambiar de estado",
  should_desactivate_product_discount: "Debes desactivar el descuento en productos para poder modificar el estado de esta promoción.",
  should_desactivate_general_discount: "Debes desactivar el descuento en toda la carta para poder modificar el estado de esta promoción.",
  discount_deleted: "Descuento eliminado",
  disactivated_discount: "Descuento desactivado",
  activated_discount: "Descuento activado",
  visible_at_webapp: "Es visible para los clientes",
  "General discount": "Descuento sobre toda la carta",
  wallet: "Monedero",
  settings: "Configuración",
  topups: "Recargas",
  wallet_settings: "Configuración del monedero",
  edit_amounts_clicking_on_inputs: "Edita los importes haciendo clic en su casilla",
  general_incentive: "Incentivo general",
  incentive_explanation: "El porcentaje del incentivo se aplicará como <1>incremento sobre el importe de la recarga.</1>",
  incentive_example: "Ejemplo: <1>Si el incentivo es de un 10%, una recarga de 10€ generará 1€ extra de créditos en el monedero.</1>",
  first_recharge_example: "Ej: 10€",
  second_recharge_example: "Ej: 15€",
  third_recharge_example: "Ej: 20€",
  forth_recharge_example: "Ej: 25€",
  fifth_recharge_example: "Ej: 50€",
  sixth_recharge_example: "Ej: 100€",
  "topup_your_wallet": "Recarga tu monedero",
  "top up your wallet in order to pay for products in this restaurant": "Recarga tu monedero para poder pagar los productos de {{restaurantName}}",
  "and you will obtain": " y obtendrás {{extraCredits}} extra",
  'of topup': "de recarga",
  topups_updated_successfully: "Actualización del monedero",
  no_topups_yet: "Aún no tienes recargas",
  "Amount payed": "Importe pagado",
  "Amount topped up": "Importe cargado",
  "wallet_search_input_placeholder": "#ID",
  "of extra credits": "de créditos extra",
  "ID topup": "ID recarga",
  "recharge_ids": "ID recarga",
  "you cannot save amounts with a value of zero": "No puedes guardar importes con valor en 0€. Edita el valor",
  select_modifier_category: "Selecciona un grupo",
  edit_modifier_category: "Configuración de grupo de modificadores",
  min_selectable_products: "Mínimo de opciones a seleccionar",
  max_selectable_products: "Máximo de opciones a seleccionar",
  individual_max_selectable_products: "Límite de selección por modificador",
  "0_optional": "0 (opcional)",
  no_limit: "Sin límite",
  img_suggestions: "<0>Sugerencias:</0> png/jpg o gif de 400x400 píxeles, entre 1MB- 2MB de peso.",
  remove_modifier_category: "Quitar grupo de modificadores",
  confirm_remove_modifier_category: "¿Estás seguro que quieres quitar el grupo {{name}} de {{parentName}}?",
  custom_schedules: 'Horarios personalizados',
  selected: 'Seleccionado',
  unselected: 'No seleccionado',
  modifier_category_updaetd: 'Grupo de modificadores actualizado',
  modifier_category_deleted: 'Grupo de modificadores eliminado',
  totalActiveCredits: "Total de créditos activos",
  totalCreditUsers: "Total de usuarios",
  totalCreditsUsed: "Total de créditos consumidos", 
  totalInvestedInIncentives: "Total invertido en incentivos",
  yes_remove: 'Sí, quitar',
  translation_progress_message: "El proceso nos llevará <1>{{minutes}}</1> aproximadamente. Mientras tanto, puedes seguir trabajando en el Back Office.",
  translations_done: "traducciones realizadas",
  go_home: "Ir a incio",
  cancel_process: "Cancelar proceso",
  no_users_with_credit_yet: "Aún no tienes usuarios con créditos",
  user: "Usuario",
  total_credits_available: "Total de créditos disponibles",
  incentive_obtained: "Incentivo obtenido",
  recharges: "recargas",
  last_recharge: "Última recarga",
  orders_with_credit: "pedidos con créditos",
  "wallet_users_search_input_placeholder": "Buscar por #email #ID",
  see_history: "Ver historial",
  get_out: "Salir",
  history_of_recharges: "Histórico de recargas",
  totalCreditUsersSubtitle: "con crédito <1>activo</1>",
  totalActiveCreditsSubtitle: "Un promedio de <1>{{average}}</1> por usuario",
  total_users_active: "usuarios activos",
  total_incentive: "incentivo total",
  total_credits: "créditos totales",
  visibility_and_schedules: 'Visibilidad y horarios',
  use_limits: 'Límite de usos',
  all_day: 'todo el día',
  no_limit: 'sin límite',
  use: 'uso',
  use_plural: 'usos',
  discount_uses: 'uso del descuento',
  discount_uses_plural: 'usos del descuento',
  discount_cents_redeemed: 'utilizado en este descuentos',
  discount_cents_redeemed_plural: 'utilizados en este descuento',
  operative: 'operativo',
  period_limit_reached: 'Límite de periodo alcanzado',
  uses_limit_reached: 'Límite de usos alcanzado',
  cents_limit_reached: 'Importe límite alcanzado',
  validity_limits: 'Límites de validez',
  create_general_discount: 'Crear descuento en toda la carta',
  edit_general_discount: 'Editar descuento en toda la carta',
  create_product_discount: 'Crear descuento en productos',
  edit_product_discount: 'Editar descuento en productos',
  create_code_coupon: 'Crear cupón de descuento',
  edit_code_coupon: 'Editar cupón de descuento',
  name_schedule_placeholder: 'Por ejemplo: "Horario semanal"',
  discount_updated: 'Descuento actualizado',
  discount_created: 'Descuento creado',
  visualize_discount_message: 'Tu {{type}} ya está en la carta. <1>Escanea o copia y pega para visualizarlo</1>.',
  done: 'listo',
  code: 'código',
  nutritional_table: 'tabla nutricional',
  ingredients: 'ingredientes',
  energy: 'energía',
  serving_size: 'porción total',
  fats: 'grasas',
  carbohydrates: 'hidratos de carbono',
  proteins: 'proteínas',
  saturated_fats: 'grasas saturadas',
  fiber: 'fibra',
  sugars: 'azúcares',
  sodium: 'sodio',
  product_details: 'detalles del producto',
  nutritional_info_explanation: 'Coloca un <1>valor igual o mayor a 0 (cero)</1> en las casillas para que la información sea visible. Las casillas vacías estarán ocultas en la webapp',
  not_specified: 'sin especificar',
  cash: 'efectivo',
  pos_terminal: "datáfono",
  access: 'Accesos webapp',
  access_management: 'Gestión de accesos',
  email_list: 'lista de correos',
  domain_list: 'lista de dominios',
  access_type: 'tipo de acceso',
  open_access: 'acceso abierto',
  login_required: 'log in obligatorio',
  restricted_access: 'acceso restringido',
  download_sheet: 'descargar plantilla',
  write_to_search: 'escribe para buscar',
  download_list: 'descargar lista',
  add_email: 'añadir correo',
  add_domain: 'añadir dominio',
  allowed_domains: 'dominios habilitados',
  domain: 'dominio',
  domain_plural: 'dominios',
  created_at: 'fecha de creación',
  modified_at: 'fecha de modificación',
  access_email_placeholder: 'Escribe un correo aquí',
  access_domain_placeholder: 'Escribe un dominio aquí',
  invalid_email: 'Correo inválido',
  invalid_domain: 'Dominio inválido',
  search_by_email: 'Buscar por correo',
  search_by_domain: 'Buscar por dominio',
  open_access_active: 'Acceso abierto activo',
  login_required_active: 'Log in obligatorio activo',
  open_access_message: 'Si deseas <b>limitar el acceso</b> a la webapp, utiliza la opción de <b>Log in obligatorio o de Acceso restringido</b>.',
  login_required_message: 'Para <b>restringir el acceso a ciertos comensales en particular</b>, utiliza la opción de <b>Acceso restringido</b>. Si deseas <b>liberar el acceso</b>, utiliza la opción de <b>Acceso abierto</b>.',
  email_whitelist_empty_state: 'Aún no tienes correos en la lista de acceso restringido',
  domain_whitelist_empty_state: 'Aún no tienes dominios en la lista',
  open_access_tooltip:  'El registro <b>no será obligatorio</b> para acceder a la webapp.',
  login_required_tooltip: 'El <b> registro/Log in será obligatorio</b> para acceder a la webapp.',
  restricted_access_tooltip: 'Para acceder a la webapp, el comensal <b>deberá estar registrado con un correo que esté en la lista o que pertenezca a un dominio habilitado</b>.',
  get_access_type_error: 'Ha ocurrido un error al cargar el tipo de acceso',
  update_access_type_error: 'Ha ocurrido un error al actualizar el tipo de acceso.',
  access_management_email_error: 'Ha ocurrido un error al cargar la lista de emails',
  access_management_domain_error: 'Ha ocurrido un error al cargar la lista de dominios',
  update_whitelist_email_error: 'Ha ocurrido un error al actualizar el email',
  update_whitelist_domain_error: 'Ha ocurrido un error al actualizar el dominio',
  delete_whitelist_email_error: 'Ha ocurrido un error al eliminar el email',
  delete_whitelist_domain_error: 'Ha ocurrido un error al eliminar el dominio',
  import_whitelist_error: 'Ha ocurrido un error al subir el archivo',
  export_whitelist_emails_error: 'Ha ocurrido un error al descargar la lista',
  info_access_import_file: 'Formatos válidos: CSV, XLSX.',
  info_access_import_template: 'Recuerda que el archivo deberá <b>respetar los campos</b> de la plantilla.',
  import_results_title: '<b>¡Genial!</b> Se ha subido tu archivo, con los siguientes cambios:',
  import_results_message: 'En total, tu lista ahora tiene <b>{{count}} {{type}}</b>.',
  added: 'añadido',
  added_plural: 'añadidos',
  updated: 'actualizado',
  updated_plural: 'actualizados',
  deleted: 'eliminado',
  deleted_plural: 'eliminados',
  delete_email: 'Eliminar correo "{{email}}"',
  delete_email_message: '¿Estás seguro que quieres eliminar este correo?',
  delete_domain: 'Eliminar dominio "{{domain}}"',
  delete_domain_message: '¿Estás seguro que quieres eliminar este dominio?',
  your_loyalty_program: 'Tu programa de fidelización',
  min_time_to_redeem: 'Tiempo mínimo para redimir el cashback',
  email_added: 'Correo añadido',
  domain_added: 'Domino añadido',
  subsidies: 'subvenciones',
  preselected: 'selección por defecto',
  subsidy_email_placeholder: 'Escribe un correo aquí',
  subsidies_empty_state: 'Aún no tienes correos en la lista',
  subsidies_error: 'Ha ocurrido un error al cargar la lista de correos',
  subsidies_coupons_error: 'Ha ocurrido un error al cargar la lista de cupones',
  update_subsidy_error: 'Ha ocurrido un error al actualizar el correo',
  delete_subsidy_error: 'Ha ocurrido un error al eliminar el correo',
  toggle_all_preselected_error: 'Ha ocurrido un error al actualizar la lista de cupones',
  import_subsidies_error: 'Ha ocurrido un error al importar el archivo',
  export_subsidies_error: 'Ha ocurrido un error al exportar la lista',
  coupon_data: 'Datos del cupón',
  type: 'tipo',
  discount_value: 'valor del descuento',
  select_coupon: 'Selecciona un cupón.',
  select_coupon_message: 'Crea más cupones desde la sección de descuentos.',
  manage_coupons: 'gestionar cupones',
  delivery_fee: 'Envío',
  gift: 'regalo',
  "See orders" : 'Ver pedidos',
  "See order": 'Ver pedido',
  "Scan the order": 'Escanea el QR en el lector o ingresa el ID del pedido',
  "Get out of the order": 'Salir del pedido',
  "Details": "Detalles",
  "starting": "a partir del",
  dates: "fechas",
  "timetable": "Horarios",
  "selection": "Selección",
  "choose one or several dates": "Escoge una o varias fechas",
  "you_have_not_selected_any_dates_yet": "Aún no tienes fechas seleccionadas",
  "when_sold_out_explanation": "Cuando se encuentren agotadas se le informará al cliente a través de la webapp.",
  "amount of tickets available": "Cantidad de entradas disponibles",
  "initial_stock_explanation" : "Puedes ajustar la cantidad de entradas, pero el cambio aplicará a todas las fechas del evento. Si ya se han vendido entradas de alguna fecha, aunque reduzcas el stock, ya se podrían haber excedido el número que estás configurando.",
  "not_repeated": "No se repite",
  "each_day": "Cada día",
  "every_month_the_date": "Cada mes el {{date}}",
  "every_month_the_weekday": "Cada semana el {{weekday}}",
  "recurrence until": "Recurrencia hasta el",
  "excluded_dates": "fechas excluidas",
  "excluded_date": "fecha excluida",
  "see": "Ver",
  "excluded_dates_explanation": "Excluye las fechas que no desees dentro de la configuración de recurrencia, haciendo clic sobre ellas en el calendario. Para volver a incluirlas abre el listado de fechas excluidas y elimina la fecha.",
  tickets_sold: "Entradas vendidas",
  available: "Disponibles",
  sold: "Vendidas",
  tickets: "Entradas",
  unlimited: "Ilimitadas",
  Ticket_stock_updated: "Stock de la entrada actualizado",
  Error_updating_stock: "Error actualizando stock",
  "Cannot delete event dates when there are associated ticket sales.": "No se pueden eliminar fechas cuando ya hay entradas vendidas para sea fecha",
  "Cannot shorten recurrence end date": "No se puede reducir la fecha de recurrencia a la fecha escogida porque ya hay ventas en esa fecha",
  "Cannot exclude date with associated ticket sales.": "No se puede excluir alguna de las fechas porque ya tiene entradas vendidas.",
  "Cannot delete event dates when there are associated ticket sales.": "No se pueden eliminar fechas que tienen entradas vendidas",
  "Cannot shorten recurrence end date": "No se puede reducir la fecha de recurrencia porque ya tiene entradas vendidas",
  "Cannot exclude date with associated ticket sales.": "No se pueden excluir fechas que ya tienen entradas vendidas.",
  "here": "aquí",
  "yumminn_connection_with": "Conexión Yumminn con",
  "connection_failed": "La conexión ha fallado",
  "successfully_connected": "Conexión realizada con exito.",
  "try_again_or_contact_an_agent": "Inténtalo de nuevo o contacta con un agente de Yumminn haciendo click",
  "connect": "Conectar",
  "disconnect": "Desconectar",
  "disconnect_yumminn_from": "¿Estás seguro de que quieres desconectar Yumminn de ",
  "disconnect_yumminn_from_message": "Tus comensales <1>no podrán pedir ni pagar a través de Yumminn, y se perderá tanto la sincronización de las mesas como de la carta.</1>",
  modifier_category_empty_state: "Añade un grupo para poder crear tus modificadores",
  define: "definir",
  visible_products: "Productos visibles",
  visible_products_with_image: "Productos visibles con imagen",
  visible_products_with_description: "Productos visibles con descripción",
  unique_products_sold: "Productos únicos vendidos",
  visible_products_sold: "Productos visibles vendidos",
  total_products_sold: "Productos vendidos totales",
  highlighted_products: "Productos destacados",
  average_score: "Nota media",
  top_products_score_info: "Se calcula según el % de productos visibles:<br/>· Con imagen (x1,3)<br/>· Vendidos (x1)<br/>· Con descripción (x0,7)",
  item: "item",
  item_plural: "items",
  unit_price: "precio unitario",
  units_sold: "unidades vendidas",
  units_prepared: "unidades preparadas",
  units_not_delivered: "unidades por entregar",
  units_delivered: "unidades entregadas",
  weight_units: "peso (unidades)",
  swap_photo: "cambiar imagen",
  add_photo: "añadir imagen",
  total_billing: "total facturado",
  weight_billing: "peso (facturación)",
  image: "Imagen",
  custom_group_individual: "Un solo producto",
  custom_group_multi: "Varios productos",
  custom_group_commercial: "Banner",
  catalog_start: "Inicio de la carta",
  l1_start: "Inicio de categoría principal",
  l2_start: "Inicio de categoría secundaria",
  l3_start: "Inicio de categoría de producto",
  go_back: "volver",
  choose_product_hihglight_title: "¿Qué tipo de destacado quieres crear?",
  one_product: "Un producto",
  multiple_products: "Varios productos",
  new_individual: "Nuevo destacado de producto",
  new_multi: "Nuevo destacado de varios productos",
  new_commercial: "Crear banner",
  edit_individual: "Editar destacado de producto",
  edit_multi: "Editar destacado de varios productos",
  edit_commercial: "Editar banner",
  position: "Posición",
  title_and_description: "Título y descripción",
  customization: "Personalización ",
  title_and_position: "Título y posición",
  details: "Detalles",
  highlighted_title: "Título del destacado",
  highlighted_title_placeholder: "Ej: ‍👩🏻‍🍳 ¡Sugerencias del chef! ",
  highlighted_position: "Posición del destacado",
  choose_stategic_position: "Elige una posición estratégica dentro de tu carta.",
  examples: "Ejemplos",
  highlighted_position_suggestion_1: "Destaca los productos <b>más vendidos, nuevos o sugerencias del chef</b> al inicio de la carta.",
  highlighted_position_suggestion_2: "Sugiere tapas que maridan perfectamente con tus vinos en la <b>categoría de vinos</b>.",
  individual_product_explanation: "Elige un producto que desees destacar",
  multi_product_explanation: "Elige los productos que quieres destacar teniendo en cuenta que deben ser <b>3</b> productos como <b>mínimo y 15 productos máximo</b>.",
  highlighted_tag: "Etiqueta del destacado (opcional)",
  highlighted_tag_placeholder: "Ej: ⭐️ Recomendado ⭐️",
  highlighted_description_placeholder: "Ej: Elige tu smoothie con frutas km0",
  banner_internal_title: 'Título (interno)',
  banner_internal_title_placeholder: 'Ej: Recomendado',
  banner_position: 'Posición del banner',
  banner_image_info: 'Especificaciones:<br/><b>Banner de 800x350 con un margen de seguridad de 50px (mínimo)</b> a cada lateral para la visibilidad completa en diferentes dispositivos.',
  force_banner_visibility: 'Mostrar el banner incluso cuando no hay productos visibles en carta.',
  title_color: 'Color del título',
  one_color: 'Un color',
  two_colors: 'Dos colores',
  background: 'Fondo',
  background_color: 'Color del fondo',
  top_color: 'Color superior',
  bottom_color: 'Color inferior',
  main_color: 'Color principal',
  extra_color: 'Color extra',
  image_info: 'Especificaciones: Imagen de 1000x350 con un margen de seguridad de 100px (mínimo) a cada lateral para la visibilidad completa en diferentes dispositivos.',
  default_product_image_title: '¿Hay productos sin fotos? <b>Añade una imagen por defecto</b>',
  add_logo: 'subir logo',
  change_logo: 'cambiar logo',
  banner: 'banner',
  highlighted: 'destacado',
  empty_schedules_title: 'Por defecto, el {{type}} estará visible durante todo el horario de servicio.',
  empty_schedules_message: 'Si deseas personalizar los momentos de visibilidad, <b>puedes definir un horario o desactivarlo</b> cuando quieras.',
  delete_custom_group_title: 'Eliminar destacado "{{name}}"',
  delete_custom_group_message: '¿Estás seguro que quieres eliminar este destacado? Como alternativa, puedes desactivarlo y no perder la configuración.',
  delete_banner_title: 'Eliminar banner "{{name}}"',
  delete_banner_message: '¿Estás seguro que quieres eliminar este banner? Como alternativa, puedes desactivarlo y no perder la configuración.',
  custom_group_finnished: 'Tu destacado ya está en la carta.<br/><b>Escanea y prueba</b> desde tu móvil.',
  events_settings: "Configuración de eventos",
  synchronize_tables: "Sincronizar mesas",
  add_price_per_sector: "Añadir precio por sector",
  manage_price_per_sector: "Gestionar precio por sector",
  sector_price_explanation: "Todos los ajustes se harán sobre el precio base del tpv. Puedes ver la actualización de cada producto en la sección de “carta”.",
  Price_per_sector: "Precio por sector",
  "You need to save the created sector first": "Tienes que guardar el sector que acabas de crear antes de crear mesas",
  "in progress": "En curso",
  "finalised": "Finalizado",
  welcome: "¡Bienvenido!",
  "login to your yumminn account": "Accede a tu cuenta Yumminn.",
  i_forgot_my_password: "He olvidado mi contraseña.",
  write_your_email: "Escribe tu correo electrónico",
  write_your_new_password: "Escribe tu nueva contraseña",
  reset_pass_mobile: "Restablecer",
  create_shift: "Crear turno",
  shift_overlap_error: "El horario seleccionado colapsa con otro turno existente.",
  shift_sending_error: "Información del turno no enviada correctamente. Recargue la página y pruebe de nuevo.",
  shift_error: "Error al guardar el turno.",
  create_restaurant_shifts: "<0>Crea los turnos</0> de tu restaurante",
  slot_duration: "Duración de slots",
  slots_quantity: "Cantidad de slots",
  dishes_quantity: "Cantidad de platos",
  delete_shift_title: "Eliminar Turno",
  delete_shift_message: "¿Estás seguro que quieres eliminar este turno? Como alternativa, puedes desactivarlo y no perder la configuración.",
  edit_shift: "editar turno",
  create_shift: "crear turno",
  shift_settings: "configuración del turno",
  dish_settings: "configuración de platos",
  save_and_continue: "guardar y continuar",
  changes_will_aply_next_day: "Los cambios realizados en este turno se aplicarán a partir de la próxima jornada.",
  name_internal: "Nombre (Interno)",
  shift_name_placeholder: "Ej: Turno de mañana",
  shift_name_error: "⚠️ Debes añadir un nombre",
  shift_start_minute_label: "Hora de apertura",
  shift_start_minute_placeholder: "Inicio",
  shift_end_minute_label: "Hora de cierre",
  shift_slot_duration: "Duración de los slots",
  shift_slot_quantity: "Cantidad de slots",
  shift_dish_quantity: "Cantidad de platos por slot",
  shift_dish_settings: "Configurar platos",
  select_dish_category: "<b>Selecciona</b> las categorías cuyos productos consideras que cuentan como <b><u>un plato</u> en la capacidad del slot.</b><br/>Ejemplo: Tal vez no quieras considerar las <b>bebidas</b> como un plato.",
  file_size_must_be_under: "El tamaño del archivo no puede superar los",
  size_not_supported: "Tamaño no soportado",
};
