import React from 'react';
import { Trans } from "react-i18next";
import ShiftsHeader from './ShiftsHeader';
import ShiftRow from './ShiftRow';
import noSchedule from '../../../../img/NoSchedule.svg'

const ShiftsTable = ({shifts, setShifts, setLoading, setShiftToRemove, setShiftToEdit}) => {
	return (
	<div>
		<ShiftsHeader disabled={shifts.length < 1}/>
		<div>
			{shifts?.map((shift) =>
				<div key={'shift' + shift.id}>
					<ShiftRow
						shift={shift}
						setShifts={setShifts}
						setLoading={setLoading}
						setShiftToRemove={setShiftToRemove}
						setShiftToEdit={setShiftToEdit}
					/>
				</div>
			)}
			{shifts.length < 1 &&
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: "10px", padding: "50px 0px", fontSize: "16px"}}>
					<img src={noSchedule} style={{height: "60px"}} alt='no_schedule' />
					<div>
						<Trans
							i18nKey='create_restaurant_shifts'
							defaults='<b>Crea los turnos</b> de tu restaurante'
							components={{ b: <b/>}}
						/>
					</div>
				</div>
			}
		</div>
	</div>
  );
};
export default ShiftsTable;
