import { WEEKDAYS } from "pages/discounts/components/constants";
import { apiAuth } from "./api";

function getDeviceInfo() {
    

    var module = {
        options: [],
        header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
        dataos: [
            { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
            { name: 'Kindle', value: 'Silk', version: 'Silk' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
            { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Linux', value: 'Linux', version: 'rv' },
            { name: 'Palm', value: 'Palm', version: 'PalmOS' }
        ],
        databrowser: [
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
        ],
        init: function () {
            var agent = this.header.join(' '),
                os = this.matchItem(agent, this.dataos),
                browser = this.matchItem(agent, this.databrowser);
            
            return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
            var i = 0,
                j = 0,
                html = '',
                regex,
                regexv,
                match,
                matches,
                version;
            
            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(string);
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                    matches = string.match(regexv);
                    version = '';
                    if (matches) { if (matches[1]) { matches = matches[1]; } }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.';
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version)
                    };
                }
            }
            return { name: 'unknown', version: 0 };
        }
    };

    var e = module.init(),
        debug = {};

    debug['os_name'] = e.os.name;
    debug['os_version'] = e.os.version;
    debug['browser_name'] = e.browser.name;
    debug['browser_version'] = e.browser.version;

    debug['navigator_userAgent'] = navigator.userAgent;

    return debug
}

export const centsToAmountString = (cents, showDecimals=true, showThousandSeparator=false) => {
    if (cents === undefined) return "0";
    let centsStr = cents.toString();
    //console.log(centsStr, 'centsStr');
    if (centsStr.lastIndexOf(".") !== -1) { //in case number is decimal
        if(centsStr.lastIndexOf(".") !== centsStr.indexOf(".")) {
            let removeIndex = cents.toString().indexOf(".");
            if(cents.length - 1 >= cents[removeIndex]) {
                delete cents[removeIndex];
            }
        }
        cents = centsStr.substring(0, centsStr.lastIndexOf(".")); //removing the dot
    }
    centsStr = cents.toString();
    let length = centsStr.length;
    if(centsStr.startsWith("-")) {
        centsStr = centsStr.substr(1, length);
        length = length - 1;
    }
    const numberOfZerosToAdd = length > 2 ? 0 : 3 - length;
    centsStr = "0".repeat(numberOfZerosToAdd) + centsStr;
    length = centsStr.length;
    let retStr = centsStr.substr(0, length - 2);
    let finalRetStr = '';
    //console.log(retStr, 'retStr');
    if (showThousandSeparator) {
        let character;
        let counter = 0;
        for (var i = retStr.length - 1; i >= 0; i--) {
            character = retStr[i];
            if (counter == 3) {
                finalRetStr = character + "." + finalRetStr;
                counter = 0;
            } else {
                finalRetStr = character + finalRetStr;
            }
            counter += 1;
        }
    } else {
        finalRetStr = retStr;
    }
    //console.log(finalRetStr, 'finalRetStr before showDecimals');
    if (showDecimals) {
        finalRetStr += "," + centsStr.substr(length - 2, 2);
    }
    return finalRetStr;
}

export function thousandsSeparator(n, {decimals = 0} = {}) {
    const thousandsRegex = /\B(?=(\d{3})+(?!\d))/g;

    const nParts = n.toString().split('.');
    
    const roundDecimal = Math.round(Number('0.' + nParts[1]) * 10 ** decimals);

    const integerString = nParts[0].replace(thousandsRegex, '.');
    const decimalString = (decimals && roundDecimal) ? ',' + roundDecimal.toString().padStart(decimals, '0') : '';

    return integerString + decimalString;
};

export const toDecimalString = (n = 0, decimals = 2) => {
    if (typeof n === 'string') n = Number(n);

    const pow = 10 ** decimals;
    n = Math.round( n * pow ) / pow;

    return n % 1 ? n.toFixed(decimals) : `${n}`;
};

export const abbreviateBigNumbers = (n, {thousands = false, rounded = false, decimals = 0} = {}) => {
    if (!rounded && n < 1) return toDecimalString(n, 2);

    if (!rounded && n < 10) return toDecimalString(n, 1);

    if (n > 999999) return toDecimalString(n / 1000000, 1) + 'M';

    if (thousands && n > 999) return toDecimalString(n / 1000, 1) + 'K';

    return thousandsSeparator(n ?? 0, {decimals});
};

export function logToServer(message, id = '') {
    try {
        const device = getDeviceInfo();

        const body = {
            "browser_name": device.browser_name,
            "browser_version": device.browser_version,
            "os_name": device.os_name,
            "os_version": device.os_version,
            "url": window.location.href,
            "agent": window.navigator.userAgent,
            "message": message,
            "session_storage": sessionStorage,
            "local_storage": localStorage,
            "token":"Yumminn2022.",
            "restaurant": id,
        }

        apiAuth.post(process.env.REACT_APP_BASE_URL+'/api/log', body);
    } catch(e) {
        console.log("logToServer catch: " + e.toString());
    }
}


export function areDatesSameDay(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export function awareDatetimeString(unawareDatetime, timeZone, onlyTime=false) {
    //console.log(unawareDatetime, 'unawareDatetime');
    const finalUnawareDatetime = unawareDatetime?.replace('T', ' ').replaceAll('-', '/');
    //console.log(finalUnawareDatetime, 'finalUnawareDatetime');
    const datetime = new Date(finalUnawareDatetime);
    //console.log(datetime, 'datetime');
    let localeArguments = {timeZone}
    if (onlyTime) {
        localeArguments = {timeZone, hour: '2-digit', minute:'2-digit'}
    }
    const localeString = datetime.toLocaleString("es-ES", localeArguments);
    //console.log(localeString, 'localeString');
    return localeString;
}

export function getRestaurantFromAppContextSelectedRestaurant(selectedRestaurant) {
    if (!selectedRestaurant) {
        return null;
    }
    if ((Array.isArray(selectedRestaurant) && selectedRestaurant.length > 0 && !!selectedRestaurant[0] && selectedRestaurant[0].label !== 'Todos') ||
        (!Array.isArray(selectedRestaurant) && selectedRestaurant.label !== 'Todos')) {
        return selectedRestaurant[0];
    }
}

export function getDates(todayCompleteDay=false) {
    let from_date;
    let to_date;
    let selectedDateFrom = JSON.parse(sessionStorage.getItem("yumminn-selectedDate"));
    let selectedDateTo = JSON.parse(sessionStorage.getItem("yumminn-selectedDateTo"));
    const period = sessionStorage.getItem("yumminn-selectedReviewFilter");
    if (!selectedDateFrom) selectedDateFrom = new Date();
    else selectedDateFrom = new Date(selectedDateFrom);
    if (!selectedDateTo) selectedDateTo = new Date();
    else selectedDateTo = new Date(selectedDateTo);

    function convertTZ(date, tzString) {
      return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
    selectedDateTo = convertTZ(selectedDateTo, 'UTC');

    const fromDate = new Date(selectedDateFrom);
    const toDate  = period === "hours" ? fromDate : selectedDateTo;
    const today = new Date();

    if (areDatesSameDay(toDate, today) && !todayCompleteDay) {
      toDate.setUTCHours(today.getHours() - 1);
      toDate.setMinutes(0);
      toDate.setSeconds(0);
      to_date = toDate;
    } else {
      to_date = toDate.setUTCHours(23, 59, 59, 59);
    }

    fromDate.setUTCHours(0, 0, 0, 0);
    from_date = new Date(fromDate);
    to_date = new Date(toDate);
    return {from_date, to_date};
  };

export const snakeToCamel = str => str.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase()); // SOME_STR => someStr

export const getTransitionEndEventName = () => {
    const transitions = {
        transition: "transitionend",
        OTransition: "oTransitionEnd",
        MozTransition: "transitionend",
        WebkitTransition: "webkitTransitionEnd"
    };

    const bodyStyle = document.body.style;

    for (const transition in transitions) {
        if (bodyStyle[transition] != undefined) {
            return transitions[transition];
        };
    };
};

export const getImageUrl = string => {
    if (!string || typeof string !== 'string') return '';

    const awsUrl = process.env.REACT_APP_AWS_S3_URL || '';
    const str = string.replace(/https:\/\/(None|localhost:\d{4}).s3.amazonaws.com/, awsUrl);
    
    try {
        return new URL(str);
    } catch {
        const path = str.startsWith('/') ? str : '/' + str;
        const baseUrl = path.startsWith('/media') ? process.env.REACT_APP_BASE_URL : awsUrl;

        try {
            return new URL(baseUrl + path);
        } catch {
            console.error(new Error(`"${str}" is not a valid URL nor path`));
            return '';
        }
    }
};

const listFormatter = new Intl.ListFormat(navigator.language || navigator.userLanguage || 'es', {
    style: 'long',
    type: 'conjunction',
});

export const stringifyDays  = (days, t, format = 'SHORT') => {
    if (days.length === 7) return t('every_day').toLowerCase();
    if (days.length === 2 && days.includes(5) && days.includes(6)) return t('weekend').toLowerCase();

    const dayKeys = WEEKDAYS[format];

    days.sort((a, b) => a - b);
    const areConsecutive = days.length > 2 && days.map((day, index) => (
        days[index + 1] - day === 1 || day === 6 && days[0] === 0
    ));

    const isRange = areConsecutive && areConsecutive.filter(isConsecutive => !isConsecutive).length === 1;

    if (isRange) {
        const lastDayIndex = areConsecutive.findIndex(isConsecutive => !isConsecutive);
        const firstDay = dayKeys[days[lastDayIndex === days.length - 1 ? 0 : lastDayIndex + 1]];
        const lastDay = dayKeys[days[lastDayIndex]];

        return `${t(firstDay)} ${format === 'SHORT' ? '~' : t('to')} ${t(lastDay)}`.toLowerCase();
    }

    const strDays = days.map(day => t(dayKeys[day]));

    return format === 'SHORT'
        ? strDays.join(", ").toLowerCase()
        : listFormatter.format(strDays).toLowerCase();
};

const timeRangeSorter = (a, b) => {
    ['start_hour', 'start_minutes', 'end_hour', 'end_minutes'].forEach(key => {
        if (a[key] !== b[key]) return a[key] - b[key];
    });

    return 0;
};

export const stringifyTimeRanges = (timeRanges, t, format = 'SHORT') => {
    const strRanges = [...timeRanges].sort(timeRangeSorter).map(time => {
        if (
            time.start_hour === 0 &&
            time.start_minutes === 0 &&
            time.end_hour === 24 &&
            time.end_minutes === 0
        ) {
            return t('all_day');
        }

        const startStr = `${time.start_hour}:${time.start_minutes.toString().padStart(2, '0')}`;
        const endStr = `${time.end_hour}:${time.end_minutes.toString().padStart(2, '0')}`;

        return format === 'SHORT'
            ? `${startStr} - ${endStr}`
            : `${t('from_(short)')} ${startStr} ${t('to')} ${endStr}`;
    });

    return format === 'SHORT'
        ? strRanges.join(", ").toLowerCase()
        : listFormatter.format(strRanges).toLowerCase();
};

export const stringifySchedule = (schedule, t, format = 'SHORT') => {
    const checkFormat = value => Object.keys(WEEKDAYS).includes(value) ? value : undefined;
    
    const dayFormat = checkFormat(format) || checkFormat(format?.days);
    const timeFormat = checkFormat(format) || checkFormat(format?.time);

    const days = stringifyDays(schedule.days, t, dayFormat);
    const timeRanges = stringifyTimeRanges(schedule.times || schedule.time_ranges, t, timeFormat);

    if (days === t('every_day').toLowerCase() && timeRanges === t('all_day').toLowerCase()) {
        return t('all_day').toLowerCase();
    }

    return `${days} ${timeRanges}`;
};

export const getGenericMultiValueHandler = setValues => {
    return value => setValues(prevValues => {
        const newValues = [...prevValues];
        const index = newValues.indexOf(value);
        
        index !== -1 ? newValues.splice(index, 1) : newValues.push(value);
        
        return newValues;
    });
};

export const parseDecimalString = str => {
    let value = str.replaceAll(/[^\d.]/g, '');

    if (value.includes('.')) {
        const index = value.indexOf('.');
        value = value.slice(0, index + 3);

        if (value.startsWith('.')) {
            value = '0' + value;
        }
    }

    return value;
};

export const parseIntegerString = str => str.replaceAll(/[^\d]/g, '');

export const calculateCalories = (item) => {
    if (
        !item ||
        (!item.serving_size && item.serving_size !== 0) ||
        ['fats', 'carbohydrates', 'proteins'].every(macro => !item[macro] && item[macro] !== 0)
    ) return null;

    const fatsKcal = Number(item.fats || 0) * 9;
    const carbsKcal = Number(item.carbohydrates || 0) * 4;
    const proteinsKcal = Number(item.proteins || 0) * 4;
    
    const serving_size = Number(item.serving_size || 0);

    const ckal = (fatsKcal + carbsKcal + proteinsKcal) * serving_size / 100;
    
    return Number(parseDecimalString(ckal.toString()));
};


export const limitSizeUpload = (event, LIMIT) => {
    const file = event.target.files?.[0];
    if (file && file.size > LIMIT * 1024 * 1024) { // limit is in MB
        event.target.value = ""; // Clear the file input
        return true;
    }
    return false;
}