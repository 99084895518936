import React, { forwardRef } from 'react';
import { InputContainer } from './InputContainers';
import { useTranslation } from 'react-i18next';
import { parseDecimalString } from 'logic/functions';
import { Icon, IconType } from 'common/Icon';

export const NumberInput = forwardRef(({
  label,
  unit,
  rightIcon,
  error,
  showErrorMessage = true,
  className = '',
  fontSize,
  height,
  step,
  parseValue = parseDecimalString, //set this to null to use the default input value
  ...inputProps
}, ref) => {
  const { t } = useTranslation();

  const handleChange = event => {
    if (!inputProps.onChange) return;

    if (parseValue) {
      event.target.value = parseValue(event.target.value);
    };

    inputProps.onChange(event);
  };

  const handleStep = sign => {
    const value = (Number(inputProps?.value || 0) + (sign * step)).toString();
    const event = { target: { value } };

    handleChange(event);
  };

  return (
    <InputContainer className={className}>
      <label>
        {label && typeof label === 'string' ? <h6 style={{width: 'max-content'}}>{t(label)}</h6> : label}
        <div className='input_wrapper' fontSize={fontSize} height={height} style={{height: height ? height : "56px"}}>
          <input
            {...inputProps}
            placeholder={t(inputProps.placeholder || '')}
            readOnly={!inputProps.onChange}
            type={inputProps.type || 'tel'}
            inputMode={inputProps.inputMode || 'decimal'}
            step={inputProps.step || '.01'}
            autoComplete={inputProps.autoComplete || 'off'}
            onChange={event => !step && handleChange(event)}
            ref={ref}
          />
          {unit}
          {rightIcon}
          {step &&
            <div className='stepper'>
              <Icon
                disabled={typeof inputProps.max === 'number' && (Number(inputProps.value) + step > inputProps.max)}
                onClick={() => handleStep(1)}
                type={IconType.CHEVRON_UP}
              />
              <Icon
                disabled={typeof inputProps.min === 'number' && (Number(inputProps.value) - step < inputProps.min)}
                onClick={() => handleStep(-1)}
                type={IconType.CHEVRON_DOWN}
              />
            </div>
          }
        </div>
      </label>
      <div className='input_info'>
        {error &&
          <i className={`error ${showErrorMessage ? '' : 'hidden'}`}>
            {t(error)}
          </i>
        }
      </div>
    </InputContainer>
  );
});