import React, { useState } from "react";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { baseAuth } from "logic/api";
import { TrashIcon } from '@heroicons/react/outline';
import { ShiftSettings } from "../../Shifts/ShiftSettings";
import { L3SlotDishSetting } from "../../Shifts/L3SlotDishSetting";
import { MINUTES_IN_DAY } from "logic/defaults";
import { ActiveSwitch } from "../ActiveSwitch";
import { Popup } from "common/Popup";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TabSelector } from "common/TabSelector";
import { Button, BUTTON_COLORS, BUTTON_VARIANTS } from "common/Button";

const ShiftModal  = ({ shift, setShiftToEdit, saveShift, setShiftToRemove, type, l3DishCount, setL3DishCount}) => {
  const { t } = useTranslation();

  const [shiftDraft, setShiftDraft] = useState(shift ? { ...shift } : null);
  const [l3DishCountDraft, setL3DishCountDraft] = useState({ ...l3DishCount });
  const [step, setStep] = useState(1);

  const changeActiveState = () => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      active: !prevShiftDraft.active,
    }});
  };

  const changeName = (name) => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      name,
    }});
  };

  const changeStartMinute = (start_minute) => {
    setShiftDraft((prevShiftDraft) => {
      const maxSlots = Math.floor((MINUTES_IN_DAY - start_minute) / prevShiftDraft.slot_duration_minutes);
      const slots_quantity = Math.min(prevShiftDraft.slots_quantity, maxSlots);

      return {
      ...prevShiftDraft,
      start_minute,
      slots_quantity,
    }});
  };

  const changeSlotDuration = event => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      slot_duration_minutes: Number(event.target.value),
    }});
  };

  const changeSlotsQuantity = event => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      slots_quantity: Number(event.target.value),
    }});
  };

  const changeDishesInParallel = event => {
    setShiftDraft((prevShiftDraft) => {
      return {
      ...prevShiftDraft,
      dishes_in_parallel: Number(event.target.value),
    }});
  };

  const saveL3DishCountDraft = async () => {
		const body = {"l3_info": l3DishCountDraft.l3}
		await baseAuth.post(`/restaurant/update_l3_dish_count`, body).then((response) => {
			const l3DishCount = response.data.shifts;
			setL3DishCount(l3DishCount);
    });
  };

  const handleNextStep = () => {
    if (step === 1) {
      saveShift(shiftDraft, false);
    } else {
      saveL3DishCountDraft();
      setStep(1);
    }
  };

  const checkDisableNextStep = () => {
    if (step === 1) {
      return !shiftDraft.slot_duration_minutes
        || !shiftDraft.slots_quantity
        || !shiftDraft.dishes_in_parallel
        || !shiftDraft.name
        || !shiftDraft.slots_quantity
        || shiftDraft.start_minute + (shiftDraft.slots_quantity * shiftDraft.slot_duration_minutes) > MINUTES_IN_DAY;
    }

    return false;
  };

  const onTabChange = tab => {
    setStep(tab === 'shift_settings' ? 1 : 2);
  };

  const cancel = () => setShiftToEdit(null);

  return (
    <ShiftPopup isOpen={!!shift} close={cancel}>
      <header>
        <h2>
          {t(type === 0 ? `edit_shift` : `create_shift`)}
        </h2>
        <ActiveSwitch
          checked={shiftDraft.active}
          onChange={changeActiveState}
        />
      </header>
      <TabSelector
        value={step === 1 ? 'shift_settings' : 'dish_settings'}
        options={['shift_settings', 'dish_settings']}
        onChange={onTabChange}
        disableTab={checkDisableNextStep}
      />
      {step === 1 &&
        <ShiftSettings
          shiftDraft={shiftDraft}
          changeName={changeName}
          changeStartMinute={changeStartMinute}
          changeSlotDuration={changeSlotDuration}
          changeSlotsQuantity={changeSlotsQuantity}
          changeDishesInParallel={changeDishesInParallel}
          setStep={setStep}
        />
      }
      {step === 2 &&
        <L3SlotDishSetting
          setL3DishCountDraft={setL3DishCountDraft}
          l3DishCountDraft={l3DishCountDraft}
        />
      }
      <footer>
        {type === 0 &&
          <Button
            label={<TrashIcon/>}
            onClick={() => setShiftToRemove(shift)}
            variant={BUTTON_VARIANTS.SECONDARY}
            color={BUTTON_COLORS.DANGER}
          />
        }
        <div>
          <Button
            label="cancel"
            onClick={cancel}
            variant={BUTTON_VARIANTS.SECONDARY}
          />
          <Button
            label={step === 1 ? type === 1 ? "create" : "save" : "save_and_continue"}
            disabled={checkDisableNextStep()}
            onClick={handleNextStep}
          />
        </div>
      </footer>
    </ShiftPopup>
  );
};

export default ShiftModal;

const ShiftPopup = styled(Popup)`
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    max-height: 100%;
    max-width: 54rem;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px; 

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.3;
      margin: 0;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-top: 16px;
    border-top: 1px solid #DDD;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      margin-left: auto;
    }
  }
`;