import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NumberInput, SelectInput } from "common/Input";
import { InputContainer, HowTo, DeleteFile } from './DeliveryStyles';
import infoIcon from '../../img/info.png';
import { components } from 'react-select';
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from 'common/Button';
import Spinner from "common/Spinner";
import { UploadIcon } from '@heroicons/react/outline';
import { useShippingInfo, updateShippingInfo, updatePreparationMinutes, uploadGeojsonFile, removeGeojsonFile, updateShippingCost } from "./DeliveryLogic";
import useRestaurant from '../../components/yumminn/useRestaurant';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import SpinnerButton from "common/SpinnerButton";
import arrowsRepeat from "../../img/arrows_repeat.svg";
import locationIcon from "../../img/location.svg";


const Delivery = () => {
  const { shippingInfo, loading } = useShippingInfo();
  const { seleccionado } = useRestaurant();
  const { t } = useTranslation();
  const [minimumShippingCost, setMinimumShippingCost] = useState(0);
  const [costPerKm, setCostPerKm] = useState(0);
  const [ETA, setETA] = useState(0);
  const [radiusInMeters, setRadiusInMeters] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [deliveryArea, setDeliveryArea] = useState([]);
  const [loadingUpdateShippingCost, setLoadingUpdateShippingCost] = useState(false)
  const [loadingUpdateDeliveryAreaInfo, setLoadingUpdateDeliveryAreaInfo] = useState(false)
  const [errorUpdate, setErrorUpdate] = useState(null)
  const [geojsonUploaded, setGeojsonUploaded] = useState(null)
  const [preparationMinutesStatus, setPreparationMinutesStatus] = useState('')
  const restaurantId = seleccionado && seleccionado[0]?.value;

  useEffect(() => {
    if(shippingInfo){
      const deliveryFeeMinCents = shippingInfo["delivery_fee_min_cents"] ? shippingInfo["delivery_fee_min_cents"] : 0
      setMinimumShippingCost(deliveryFeeMinCents)

      const deliveryFeeCentsPerKm = shippingInfo["delivery_fee_cents_per_km"] ? shippingInfo["delivery_fee_cents_per_km"] : 0
      setCostPerKm(deliveryFeeCentsPerKm)

      const preparationMinutes = shippingInfo["preparation_minutes"] ? shippingInfo["preparation_minutes"] : ''
      setETA(preparationMinutes)

      const deliveryRadiusMeters = shippingInfo["delivery_radius_meters"] ? shippingInfo["delivery_radius_meters"] : 0
      setRadiusInMeters(deliveryRadiusMeters)

      const lat = shippingInfo["latitude"] ? shippingInfo["latitude"] : 0
      setLatitude(lat)

      const long = shippingInfo["longitude"] ? shippingInfo["longitude"] : 0
      setLongitude(long)
      
      if(shippingInfo["geojson"]){
        setGeojsonUploaded("uploaded")
        setDeliveryArea( { value: t("geojson"), label: t("geojson")})
      } else {
        setDeliveryArea( { value: t("radius"), label: t("radius") })
        setGeojsonUploaded(null)
      }
    }

  }, [shippingInfo])


  const handleMinimumShippingCost = (e) => {
    let newValue = e.target.value
    const maxDecimals = 2;
    const decimalIndex = newValue.indexOf(".");
    if (decimalIndex !== -1) {
      newValue = newValue.slice(0, decimalIndex + maxDecimals + 1);
    }
    const minShippingCost = Number(newValue)
    setMinimumShippingCost(minShippingCost * 100)
  }

  const handleCostPerKm = (e) => {
    let newValue = e.target.value
    const maxDecimals = 2;
    const decimalIndex = newValue.indexOf(".");
    if (decimalIndex !== -1) {
      newValue = newValue.slice(0, decimalIndex + maxDecimals + 1);
    }
    const costPerKm = Number(newValue)
    setCostPerKm(costPerKm * 100)
  }

  const saveShippingCostInfo = async () => {
    setLoadingUpdateShippingCost(true);
    try {
        const { updated, loading, errorMsg } = await updateShippingCost({
          minimum_shipping_cost: minimumShippingCost, 
          cost_per_km: costPerKm, 
          restaurant_id: restaurantId
        });
        if(updated){
          toast.success(`${t('update_ok')}`);
        }
        if (errorMsg) {
            setErrorUpdate(errorMsg);
        } else {
            setLoadingUpdateShippingCost(loading);
        }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingUpdateShippingCost(false);
    }
  };


  const handleETA = (e) => {
    setETA(e.target.value)
  }

  const saveETA = async () => {
    if(ETA !== Number(shippingInfo["preparation_minutes"])){
      setPreparationMinutesStatus('loading')
      const preparationMinutesUpdatedStatus = await updatePreparationMinutes(ETA, restaurantId)
      setPreparationMinutesStatus(preparationMinutesUpdatedStatus)
      setTimeout(() => {
        setPreparationMinutesStatus('');
      }, 500);
    }
  }


  const handleSelectDeliveryArea = (area) => {
		setDeliveryArea(area)
  }

  const handleRadiusInMeters = (e) => {
    setRadiusInMeters(Number(e.target.value))
  } 

  const handleLatitude = (e) => {
    const lat = parseFloat(e.target.value.replace(/,/g, '.'))
    setLatitude(lat)
  } 

  const handleLongitude = (e) => {
    const long = parseFloat(e.target.value.replace(/,/g, '.'))
    setLongitude(long)
  } 

  const uploadGeojson = async (e) => {
    setGeojsonUploaded("uploading")
    const fileInput = e.target;
    const geojson = fileInput.files[0];

    if (!geojson) {
      toast.error(t('No file selected'));
      setGeojsonUploaded(null)
      return;
    }

    // Check if the file extension is .json
    const fileName = geojson.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileExtension !== 'geojson') {
        toast.error(t('Invalid file format. Please select a .geojson file.'));
        setGeojsonUploaded(null);
        return;
    }

    try{
      const res = await uploadGeojsonFile(geojson, restaurantId)
      setGeojsonUploaded(res)
    } catch(error){
      setGeojsonUploaded(null)
    }
    
  }

  const removeGeojson = async () => {
    setGeojsonUploaded("uploading")
    try{
      const res = await removeGeojsonFile(restaurantId)
      setGeojsonUploaded(res)
    } catch(error){
      setGeojsonUploaded(null)
    }
  }

 

  const saveDeliveryAreaInfo = async () => {
    setLoadingUpdateDeliveryAreaInfo(true);
    try {
        const { updated, loading, errorMsg } = await updateShippingInfo({
          delivery_radius_meters: radiusInMeters, 
          latitude: latitude, 
          longitude: longitude,
          restaurant_id: restaurantId
        });
        if(updated){
          toast.success(`${t('update_ok')}`);
        }
        if (errorMsg) {
            setErrorUpdate(errorMsg);
        } else {
          setLoadingUpdateDeliveryAreaInfo(loading);
        }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingUpdateDeliveryAreaInfo(false);
    }
  }

  const options = [
    { value: t("radius"), label: t("radius") },
    { value: t("geojson"), label: t("geojson")}
  ];
  
  if(loading){
    return(
      <Spinner></Spinner>
    )
  }

  if( errorUpdate ) {
    toast.error(t('error_updating_delivery'))
  }

  return (
    <div className='flex flex-wrap w-full p-4 gap-6 justify-start'>

      <div className="flex flex-col align-center bg-[#FFF] rounded-3xl p-9 gap-4">
        <div id='card_title' className='flex flex-row gap-2'>
          <span className="text-xl font-bold text-[#2D3748]">{t('shipping cost')}</span>
        </div>
      
        <div id='separator' className='border-solid border border-[#BFBFBF]'></div>

        <InputContainer>
          <NumberInput
            type="number"
            pattern="[0-9]*[.,]?[0-9]+"
            min={0.01}
            name='minimum_shipping_cost'
            label={t('minimum_shipping_cost')}
            value={minimumShippingCost > 0 ? +(minimumShippingCost / 100).toFixed(2) : 0}
            className='shipping_input'
            unit={'€'}
            onChange={handleMinimumShippingCost}
          />
          <NumberInput
            type="number"
            pattern="[0-9]*[.,]?[0-9]+"
            min={0.01}
            name='cost_per_km'
            label={t('cost_per_km')}
            value={costPerKm > 0 ? +(costPerKm / 100).toFixed(2) : 0}
            className='shipping_input'
            unit={'€'}
            onChange={handleCostPerKm}
          />
        </InputContainer>
        <Button 
          mr={2} 
          size={BUTTON_SIZES.LG}
          onClick={saveShippingCostInfo}
          disabled={loadingUpdateShippingCost}
          loading={loadingUpdateShippingCost}
          label = {t('save')}
        />

      </div>

      <div className="flex flex-col align-center bg-[#FFF] rounded-3xl p-9 gap-4">
        <span id='card_title' className="text-xl font-bold text-[#2D3748]">{t('delivery area')}</span>
        <div id='separator' className='border-solid border border-[#BFBFBF]'></div>
          <div style={{fontSize: '16px', marginTop: "16px", marginBottom: "-6px", display: 'flex', alignItems: 'center'}}>
            {t('type_of_delivery_area')}
          </div>
          <SelectInput
            id={'delivery_area'}
            name={'delivery_area'}
            isClearable={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            value={deliveryArea}
            options={options}
            className={'w-80'}
            onChange={handleSelectDeliveryArea}
            components={{Option}}
          />

        {deliveryArea["value"] === t('radius') && 
          <>
            <NumberInput
              type="number"
              pattern="[0-9]*[.,]?[0-9]+"
              min={0}
              name='radius_in_meters'
              label={t('radius_in_meters')}
              value={radiusInMeters}
              unit={'m'}
              className='shipping_input'
              onChange={handleRadiusInMeters}
            />
            <NumberInput
              type="number"
              pattern="[0-9]*[.,]?[0-9]+"
              min={0.01}
              name='latitude'
              label={t('latitude')}
              value={latitude}
              className='shipping_input'
              onChange={handleLatitude}
            />
            <NumberInput
              type="number"
              pattern="[0-9]*[.,]?[0-9]+"
              min={0.01}
              name='longitude'
              label={t('longitude')}
              value={longitude}
              className='shipping_input'
              onChange={handleLongitude}
            />
            <HowTo type='coordinates' href="https://tutoriales.guidejar.xyz/guides/a64ec3f5-e3b1-402d-b320-28558cfc504d" target='_blank'>
              <Trans i18nKey={"how_to_obtain_coordinates"}></Trans>
            </HowTo>
            <Button
              size={BUTTON_SIZES.LG}
              className = 'ml-6 mr-6'
              disabled={loadingUpdateDeliveryAreaInfo}
              loading={loadingUpdateDeliveryAreaInfo}
              onClick={saveDeliveryAreaInfo}
              label = {t('save')}
            />
          </>
        }

        {deliveryArea["value"] === t('geojson') &&
          <>
            <div style={{fontSize: '16px', marginTop: "16px", marginBottom: "-6px", display: 'flex', alignItems: 'center'}}>
                {t('upload_geojson')}
            </div>
            <HowTo type='geojson' href="https://tutoriales.guidejar.xyz/guides/9bb130f5-0243-414a-829e-a6c7e9195294" target='_blank'>
              {t('how_to_create_geojson')}
            </HowTo>
            {geojsonUploaded === 'uploaded' && 
              <div className='flex justify-center w-full'>
                <img src={locationIcon} className='h-20 w-20' alt=''/>
              </div>
            }
            {(geojsonUploaded === 'removed' || geojsonUploaded === 'uploaded' || !geojsonUploaded) &&
              <input
                  type="file"
                  id="geojsonFileInput"  
                  accept=".geojson"
                  style={{ display: 'none' }}  
                  onChange={uploadGeojson}
              />
            }
            {geojsonUploaded === 'uploaded' ? 
              <Button 
                size={BUTTON_SIZES.LG}
                onClick={() => document.getElementById('geojsonFileInput').click()}
                label = {t('modify_file')}
                variant={BUTTON_VARIANTS.SECONDARY}
                rightIcon={ <img src={arrowsRepeat} className='h-8 w-8 ml-5 mb-0.5' alt=''/>}
                disabled={geojsonUploaded === 'uploading'}
                loading={geojsonUploaded === 'uploading'}
              />
              :
              <Button 
                size={BUTTON_SIZES.LG}
                onClick={() => document.getElementById('geojsonFileInput').click()}
                label = {t('upload_file')}
                className = 'mt-6'
                rightIcon={ <UploadIcon className='h-4 w-4 ml-5 mb-0.5' />}
                disabled={geojsonUploaded === 'uploading'}
                loading={geojsonUploaded === 'uploading'}
              />
            }
            {geojsonUploaded === 'uploaded' && 
              <DeleteFile
                style={{cursor: geojsonUploaded === 'uploading' ? "not-allowed" : "pointer"}}
                onClick={removeGeojson}
              >
                {t('delete_file')}
              </DeleteFile>
            }
          </>
        }

      </div>

      <div className="flex flex-col align-center bg-[#FFF] rounded-3xl p-9 gap-4">
        <span id='card_title' className="text-xl font-bold text-[#2D3748]">{t("delivery_time")}</span>
        <div id='separator' className='border-solid border border-[#BFBFBF]'></div>
        <div id='info_ETA' className='gap-1 flex flex-row'>
          <img
            src={infoIcon}
            alt={"info-icon"}
            className='w-5 h-5 mr-1'
          />
          <div>
            <Trans i18nKey={"ETA_explanation"}>
              It is the <span style={{fontWeight: 600}}>waiting time</span> we will communicate to the customer. It includes <div style={{fontWeight: 600}}>order preparation and shipping.</div>
            </Trans>
          </div>
        </div>
        <InputContainer>
          <NumberInput
              type="number"
              pattern="[0-9]*[.,]?[0-9]+"
              name='estimated_delivery_time'
              label={t('estimated_delivery_time')}
              value={ETA}
              unit={'min'}
              className='shipping_input'
              onChange={handleETA}
              onBlur={saveETA}
            />
            {preparationMinutesStatus  === 'loading' && 
              <div 
                className="absolute right-28 top-14"
              >
                  <SpinnerButton />
              </div>
            }
            {preparationMinutesStatus  === 'updated' && 
              <CheckCircleIcon
                  style={{marginTop: '3.5px'}}
                  className="absolute right-28 top-12 h-8 w-8"
              />
            }
        </InputContainer>
        
      </div>
       
    </div>
  );
};


const Option = (props) => (
	<components.Option {...props}>
		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
			<label>{props.label}</label>
			<input
				className="checkboxDeliverySelect"
				type="checkbox"
				checked={props.isSelected}
				onChange={() => null()}
			/>
			<span/>
		</div>
	</components.Option>
)




export default Delivery;